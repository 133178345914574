import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,[_c(VCol,[_c(VCard,{staticClass:"pa-lg-5 mx-auto",class:_vm.$config.blurred_ui ? 'blurred-background' : null,attrs:{"color":_vm.$config.blurred_ui ? 'transparent' : 'white',"dark":_vm.$config.fields_color_toggle,"shaped":_vm.$config.shaped_widgets,"flat":"","max-width":"600"}},[_c('div',{staticClass:"text-left"},[_c('a',{staticClass:"text-decoration-none text-center",on:{"click":function($event){return _vm.$router.push('/login')}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-arrow-left")]),_vm._v(" "+_vm._s(_vm.$t("gotoLogin")))],1)]),_c(VCardTitle,{staticClass:"mx-5 mb-2 ml-0"},[_vm._v(_vm._s(_vm.$t("forgotUsername"))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$config.app_name))]),_c(VCardText,[(_vm.success)?_c(VAlert,{staticClass:"my-9",attrs:{"outlined":"","transition":"","type":"success","border":"left","color":"success"}},[_vm._v(" "+_vm._s(_vm.$t("forgotUsernameRequested"))+" ")]):_vm._e(),(_vm.error)?_c(VAlert,{attrs:{"outlined":"","transition":"","type":"error","border":"left","color":"error"}},[_vm._v(" "+_vm._s(_vm.error_message)+" ")]):_vm._e(),(!_vm.success)?_c(VForm,{ref:"forgot_username",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VTextField,{attrs:{"label":_vm.$t('emailAddress'),"filled":"","rules":_vm.emailRules,"disabled":_vm.loading},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1):_vm._e(),(!_vm.success)?_c(VBtn,{staticClass:"primary",attrs:{"rounded":_vm.$config.shaped_widgets,"loading":_vm.loading,"disabled":_vm.loading,"block":"","x-large":_vm.$vuetify.breakpoint.mdAndUp},on:{"click":function($event){return _vm.reset()}}},[_vm._v(_vm._s(_vm.$t("sendUsernameRequest")))]):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }