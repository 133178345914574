import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"widget",attrs:{"shaped":_vm.$config.shaped_widgets}},[_c(VCardTitle,{attrs:{"flat":""}},[_c(VCardTitle,{staticClass:"mb-0 py-0 font-weight-bold text--secondary"},[_vm._v(_vm._s(_vm.$t("balance")))]),_c(VSpacer),_c(VIcon,{staticClass:"align-right mr-3",attrs:{"x-large":""}},[_vm._v("mdi-currency-eur")])],1),_c(VCardText,{staticClass:"widget-height"},[_c('h1',{staticClass:"d-block text-center justify-center mt-8 font-weight-bold text-h3"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.balance))+" ")])]),_c(VFooter,{class:_vm.mollieActive ? '' : 'pa-5',attrs:{"dark":"","color":"primary"}},[_c(VSpacer),(_vm.mollieActive)?_c(VBtn,{attrs:{"text":"","small":""},on:{"click":function($event){return _vm.$store.dispatch('setShowDepositModal', true)}}},[_vm._v(" "+_vm._s(_vm.$t("addToBalance"))+" "),_c(VIcon,{attrs:{"right":"","small":""}},[_vm._v("mdi-arrow-right")])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }