<template>
    <div class="block-card">
        <div class="block-card-content">
            <div>
                <h3>{{ $t('blockMyCard') }}</h3>
                <span>{{ $t('blockCardText') }}</span>
            </div>

            <template v-if="status == 'success'">
                <v-alert class="w-100 mb-0 mt-6" outlined transition type="success" prominent border="left" color="success">
                    {{ $t('cardHasBeenBlocked') }}
                </v-alert>
            </template>
            <template v-if="status == 'error'">
                <v-alert class="w-100 mb-0" outlined transition type="error" prominent border="left" color="error">
                    {{ errorMessage }}
                </v-alert>
            </template>
            <div class="align-center mt-8 w-100">
                <v-btn :disabled="status == 'success'" :loading="status == 'loading'" block rounded color="primary"
                    @click="blockCard">
                    {{ $t('blockCard') }}
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "block-card",
    data() {
        return {
            status: 'idle',
            errorMessage: '',
        };
    },
    mounted() {
        // 
    },
    methods: {
        blockCard() {
            this.status = 'loading';

            //  we get the card from the store
            let card = this.$store.getters.selectedCard;

            // if no card is selected, we shouw an error
            if (!card) {
                this.status = 'error';
                this.errorMessage = this.$t('Er is geen kaart geselecteerd');
                return;
            }

            this.$http
                .post(this.$config.backend_url + "/cards/block", {
                    accesstoken: card.token,
                })
                .then((response) => {
                    if (response.status == 200) {
                        this.status = 'success';
                    } else {
                        this.status = 'error';
                        this.errorMessage = this.$t('Er is een fout opgetreden');
                    }

                })
                .catch((error) => {
                    return error;
                });
        },
        redirectDashboard() {
            this.$router.push("dashboard");
        },
        redirectTransactions() {
            this.$router.push("transactions");
        },
    },
};
</script>
