<template>
    <v-card>
        <v-card-title>{{ $t("paymentStatus") }}</v-card-title>
        <v-card-subtitle> </v-card-subtitle>
        <v-card-text>
            <template v-if="loading">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </template>
            <template v-if="!loading && success">
                <v-alert outlined transition type="success" prominent border="left" color="success">
                    {{ paymentstatus }}
                </v-alert>
                <v-card-text v-if="printableInvoice">
                    <p>{{ $t("printInvoiceText") }}</p>
                    <v-btn @click="redirectTransactions" color="primary" outlined>{{ $t("goToTransactions") }}</v-btn>
                </v-card-text>
            </template>
            <template v-if="!loading && !success">
                <v-alert outlined transition type="error" prominent border="left" color="error">
                    {{ paymentstatus ? paymentstatus : $t("somethingWentWrong") }}
                </v-alert>
            </template>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: "check-payment",
    data() {
        return {
            paymentstatus: "",
            loading: true,
            error: false,
            error_response: "",
            printableInvoice: this.$config.printable_invoice,
        };
    },
    mounted() {
        this.getBalance(this.$store.getters.selectedCard.token);
        this.getPaymentStatus();
    },
    methods: {
        getBalance(token) {
            this.$http
                .get(this.$config.backend_url + "/balance/" + token)
                .then((response) => {
                    this.$store.dispatch("setSelectedCardBalance", response.data.amount);
                })
                .catch((error) => {
                    return error;
                });
        },
        getPaymentStatus() {
            this.$http
                .get(this.$config.backend_url + "/transaction")
                .then((response) => {
                    this.loading = false;

                    if (response.data.status == "CANCELED" || response.data.status == "EXPIRED") {
                        this.success = false;
                        this.paymentstatus = this.$t("paymentCanceled");
                    }
                    if (response.data.status == "FAILED") {
                        this.success = false;
                        this.paymentstatus = this.$t("paymentFailed");
                    }
                    if (response.data.status == "PAID") {
                        this.success = true;
                        this.paymentstatus = this.$t("paymentSucces");
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    this.error = true;
                    this.error_response = error;
                });
        },
        redirectDashboard() {
            this.$router.push("dashboard");
        },
        redirectTransactions() {
            this.$router.push("transactions");
        },
    },
};
</script>
