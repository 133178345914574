import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"widget",attrs:{"shaped":_vm.$config.shaped_widgets}},[_c(VCardTitle,{staticClass:"mb-0",attrs:{"flat":""}},[_c(VCardTitle,{staticClass:"mb-0 py-0 font-weight-bold text--secondary"},[_vm._v(_vm._s(_vm.$t("transactions")))]),_c(VSpacer),_c(VIcon,{staticClass:"align-right mr-3",attrs:{"x-large":""}},[_vm._v("mdi-cart")])],1),_c(VCardText,[(_vm.loading)?_c('loader'):_c(VDataTable,{staticClass:"adjusted-margin",attrs:{"headers":_vm.headers,"items":_vm.transactions,"sort-desc":"","sort-by":"time","hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item.providerName",fn:function({ item }){return [(item.providerName)?_c('div',[_c('small',{attrs:{"title":_vm.$t(item.providerName)}},[_vm._v(_vm._s(_vm.getProviderName(item.providerName)))])]):_c('div',[_c('small',[_vm._v(_vm._s(_vm.$t("depositedAmount")))])])]}},{key:"item.amount",fn:function({ item }){return [_c('small',[_vm._v(_vm._s(_vm._f("currency")(item.amount)))])]}},{key:"item.time",fn:function({ item }){return [_c('small',[_vm._v(" "+_vm._s(_vm._f("moment")(item.time,"l")))])]}}])})],1),_c(VFooter,{attrs:{"dark":"","color":"primary"}},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","to":'transactions',"small":""}},[_vm._v(" "+_vm._s(_vm.$t("allTransactions"))+" "),_c(VIcon,{attrs:{"right":"","small":""}},[_vm._v("mdi-arrow-right")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }