<template>
    <div>
        <v-row>
            <v-col>
                <v-card class="pa-lg-3" flat>
                    <v-card-title>{{ $t("wallets") }}</v-card-title>
                    <v-card-text>
                        <v-data-table :loading="loading" :headers="wallet_headers" :items="wallets" sort-desc
                            sort-by="time">
                            <template v-slot:item.name="{ item }">
                                <div v-if="item.type === 'RECHARGEABLE'">
                                    {{ $t("depositedAmount") }}
                                </div>
                                <div v-else>{{ removeUnderscore(item.name) }}</div>
                            </template>
                            <template v-slot:item.validityStart="{ item }">
                                {{ item.validityStart | moment("L") }}
                            </template>
                            <template v-slot:item.validityEnd="{ item }">
                                {{ item.validityEnd | moment("L") }}
                            </template>

                            <template v-slot:item.amount="{ item }">
                                <span v-if="item.unit == 'EURO'">
                                    {{ item.amount | currency }}
                                </span>

                                <span v-else>
                                    {{ item.amount }}
                                </span>
                            </template>
                        </v-data-table>
                    </v-card-text>

                    <v-card-title>{{ $t("transactions") }}</v-card-title>
                    <v-card-text>
                        <v-data-table :loading="loading" :headers="transaction_headers" :items="transactions" sort-desc
                            sort-by="time">
                            <template v-slot:item.amount="{ item }">
                                <span v-if="item.unit == 'EURO'">
                                    {{ item.amount | currency }}
                                </span>

                                <span v-else>
                                    {{ item.amount }}
                                </span>

                            </template>
                            <template v-slot:item.time="{ item }">
                                {{ item.time | moment("L") }}
                            </template>
                            <template v-slot:item.status="{ item }">
                                {{ $t(item.status) }}
                            </template>
                            <template v-slot:item.type="{ item }">
                                {{ $t(item.type) }}
                            </template>



                            <template v-slot:item.download="{ item }" v-if="printableInvoice === true">
                                <v-btn small @click="downloadTransaction(item.id, item.amount, item.time)">{{
                                    $t("Download")
                                    }}</v-btn>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    components: {},
    name: "Transactions",
    data() {
        return {
            loading: false,
            wallet_headers: [
                {
                    text: this.$t("description"),
                    align: "start",
                    sortable: true,
                    value: "name",
                },
                {
                    text: this.$t("validFrom"),
                    align: "start",
                    sortable: true,
                    value: "validityStart",
                },

                {
                    text: this.$t("validTo"),
                    align: "start",
                    sortable: true,
                    value: "validityEnd",
                },
                {
                    text: this.$t("balance"),
                    align: "end",
                    sortable: true,
                    value: "amount",
                },
            ],
            transactions: [],
            wallets: [],
            printableInvoice: this.$config.printable_invoice,
        };
    },
    created() {
        this.getTransactions();
        this.getWallets();
    },
    methods: {
        getTransactions() {
            if (this.$store.getters.selectedCard) {
                this.loading = true;
                this.$http
                    .get(this.$config.backend_url + "/transactions?token=" + this.$store.getters.selectedCard.token)
                    .then((response) => {
                        this.transactions = response.data;
                        this.loading = false;
                    });
            }
        },
        getWallets() {
            if (this.$store.getters.selectedCard) {
                this.loading = true;
                this.$http
                    .get(this.$config.backend_url + "/cards/" + this.$store.getters.selectedCard.token)
                    .then((response) => {
                        this.wallets = response.data;
                        this.loading = false;
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },
        removeUnderscore(string) {
            return this.$t(string.replace("_", " "));
        },
        downloadTransaction(transactionId, amount, time) {
            const printEndpoint = this.$config.backend_url + "/download/transaction";

            let data = {
                transactionId: transactionId,
                amount: amount,
                token: this.$store.getters.selectedCard.token,
                time: time,
            };

            this.$http
                .post(printEndpoint, data, { responseType: "blob" }) // Setting the responseType directly in the method call
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `${this.$config.invoice_name}${transactionId}.pdf`); // Generate a dynamic name based on the transaction ID
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => {
                    // Handle the error appropriately
                    console.error("Error downloading the PDF", error);
                });
        },
    },
    watch: {
        "$store.getters.selectedCard": function () {
            this.getWallets();
            this.getTransactions();
        },
    },
    computed: {
        transaction_headers() {
            let baseHeaders = [
                {
                    text: this.$t("date"),
                    align: "start",
                    sortable: true,
                    value: "time",
                },
                {
                    text: this.$t("status"),
                    align: "start",
                    sortable: true,
                    value: "status",
                },
                {
                    text: this.$t("type"),
                    align: "start",
                    sortable: true,
                    value: "type",
                },
                {
                    text: this.$t("shop"),
                    align: "start",
                    sortable: true,
                    value: "partnerName",
                },
                {
                    text: this.$t("amount"),
                    align: "start",
                    sortable: true,
                    value: "amount",
                },
            ];

            if (this.printableInvoice === true) {
                baseHeaders.push({
                    text: this.$t("Download"),
                    align: "end",
                    sortable: false,
                    value: "download",
                });
            }

            return baseHeaders;
        },
    },
};
</script>

<style lang="scss"></style>
