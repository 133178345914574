<template>
  <v-card :shaped="$config.shaped_widgets" class="widget">
    <v-card-title flat>
      <v-card-title class="mb-0 py-0 font-weight-bold text--secondary">{{ $t("lostCard") }}</v-card-title>
      <v-spacer></v-spacer>
      <v-icon class="align-right mr-3" x-large>mdi-credit-card-off</v-icon>
    </v-card-title>

    <v-card-text class="widget-height">
      <div class="widget-text">
        {{ $t("requestPassText") }}
      </div>
    </v-card-text>
    <v-footer dark color="primary">
      <v-spacer></v-spacer>
      <v-btn text :to="'lost-card'" small>
        {{ $t("requestPass") }}
        <v-icon right small>mdi-arrow-right</v-icon>
      </v-btn>
    </v-footer>
  </v-card>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style></style>
