<template>
  <v-card :shaped="$config.shaped_widgets" class="widget">
    <v-card-title flat>
      <v-card-title class="mb-0 py-0 font-weight-bold text--secondary">Pas verlengen</v-card-title>
      <v-spacer></v-spacer>
      <v-icon class="align-right mr-3" x-large>mdi-credit-card</v-icon>
    </v-card-title>

    <v-card-text class="widget-height">
      <div class="widget-text">
        Ipsum dolor sit amet consectetur adipiscing elit duis tristique.
        Adipiscing elit duis tristique sollicitudin. Neque laoreet suspendisse
        interdum consectetur libero id faucibus nisl tincidunt.
      </div>
    </v-card-text>
    <v-footer dark color="primary">
      <v-spacer></v-spacer>
      <v-btn text :to="'informal-care'" small>
        Mijn pas verlengen
        <v-icon right small>mdi-arrow-right</v-icon>
      </v-btn>
    </v-footer>
  </v-card>
</template>


<script>
export default {
  data() {
    return {};
  },
};
</script>

<style>
</style>