<template>
    <div>
        <v-row>
            <v-col>
                <v-text-field
                    v-model="local_address.postal_code"
                    :label="$t('postalCode')"
                    v-mask="'####AA'"
                    validate-on-blur
                    :rules="postalCode"
                    class="uppercase required"
                    :disabled="disabled"
                ></v-text-field>
            </v-col>
            <v-col>
                <v-text-field
                    v-model="local_address.housenumber"
                    :label="$t('housenumber')"
                    :rules="[required, housenumberRule]"
                    @keypress="isNumberOrLetter($event)"
                    @keyup.enter="getAddress()"
                    @blur="getAddress()"
                    validate-on-blur
                    :disabled="disabled"
                    class="required"
                >
                </v-text-field>
            </v-col>
            <v-col>
                <v-text-field
                    v-model="local_address.housenumber_addition"
                    :label="$t('housenumberAddition')"
                    @keypress="isNumberOrLetter($event)"
                    @keyup.enter="getAddress()"
                    @blur="getAddress()"
                    validate-on-blur
                    :disabled="disabled"
                >
                    <template slot="append-outer">
                        <v-btn
                            :disabled="disabled"
                            :loading="loading_address"
                            ref="search"
                            @click="getAddress()"
                            color="primary"
                            small
                            icon
                        >
                            <v-icon>mdi-magnify</v-icon>
                        </v-btn>
                    </template>
                </v-text-field>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-text-field
                    v-model="local_address.street"
                    :label="$t('street')"
                    :rules="[required]"
                    :loading="loading_address"
                    :disabled="true"
                ></v-text-field>
            </v-col>
            <v-col>
                <v-text-field
                    v-model="local_address.city"
                    :label="$t('city')"
                    :rules="[required]"
                    :loading="loading_address"
                    :disabled="true"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row v-if="not_found">
            <v-col>
                <v-alert outlined transition type="error" border="left" color="error">
                    {{ $t("noAddressFound") }}
                </v-alert>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    components: {},
    props: ["address", "label", "disabled"],
    data() {
        return {
            required: (value) => !!value || this.$t("mandatory"),
            housenumberRule: (value) => !value || /[^.,]$/.test(value) || this.$t("noDecimalSigns"),
            local_address: {
                postal_code: null,
                housenumber: null,
                housenumber_addition: null,
                street: null,
                city: null,
            },
            loading_address: false,
            not_found: false,
            postalCode: [
                (v) => !!v || this.$t("mandatory"),
                (v) => !v || /^(?:NL-)?(\d{4})\s*([A-Za-z]{2})$/.test(v) || this.$t("invalidPostalCode"),
            ],
        };
    },
    created() {
        this.local_address = this.address;
    },
    methods: {
        isNumberOrLetter(event) {
            if (!/[a-zA-Z0-9\s]/.test(event.key)) {
                event.preventDefault();
            }
        },
        getAddress() {
            if (!this.local_address.postal_code || !this.local_address.housenumber) {
                return;
            }

            this.loading_address = true;

            this.$http
                .get(
                    this.$config.backend_url +
                        "/address-lookup?housenumber=" +
                        this.local_address.housenumber +
                        "&postal_code=" +
                        this.local_address.postal_code +
                        "&housenumber_addition=" +
                        this.local_address.housenumber_addition
                )
                .then((response) => {
                    this.loading_address = false;
                    if (response.data.status == "ok") {
                        this.local_address.street = response.data.street;
                        this.local_address.city = response.data.city;
                        this.not_found = false;
                    } else {
                        this.not_found = true;
                        this.local_address.street = "";
                        this.local_address.city = "";
                    }
                })
                .catch(() => {
                    this.loading_address = false;
                    this.loading = false;
                });
        },
    },
    watch: {
        address() {
            this.local_address = this.address;
        },
        "local_address.postal_code": function (newPostalCode, oldPostalCode) {
            if (newPostalCode !== oldPostalCode) {
                this.$emit("update:postalCode", newPostalCode);
            }
        },
    },
};
</script>

<style>
.uppercase input {
    text-transform: uppercase;
}
</style>
