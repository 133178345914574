<template>
  <router-view />
</template>

<script>
export default {
  name: "Embed",

  data() {
    return {

    };
  },
  created() {
    this.language = this.$store.getters.language;
  },
  methods: {},
  computed: {

  },
  watch: {

  },
};
</script>

<style lang="scss">
</style>
