<template>
  <div>
    <v-navigation-drawer dark color="primary" clipped v-model="drawer" app right temporary>
      <v-list nav>
        <v-toolbar flat class="ma-0 pa-0 transparent">
          <v-subheader class="text-uppercase font-weight-bold" style="margin-left: -25px">Menu</v-subheader>
          <v-spacer></v-spacer>
          <v-btn @click="drawer = false" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-list-item v-for="(item, index) in menu" :key="index" :to="item.link">
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <div class="pa-2">
          <v-btn block color="primary" outlined> {{ $t("logout") }} </v-btn>
        </div>
      </template>
    </v-navigation-drawer>


    <v-app-bar fixed elevate-on-scroll color="primary">

      <v-container class="py-0 px-0 px-sm-2 fill-height">
        <!-- Only desktop views -->
        <a v-if="$config.return_url" style="text-decoration: none" :href="$config.return_url">
          <v-icon class="mr-1" small color="white">mdi-open-in-new</v-icon>
          <small v-if="!$vuetify.breakpoint.mdAndDown" class="white--text">
            {{ $t("backTo") }}
          </small>
        </a>

        <router-link v-if="$vuetify.breakpoint.mdAndDown" to="dashboard"
          class="d-flex align-center text-decoration-none mr-2">
          <h4 class="white--text mt-1">{{ $config.app_name }}</h4>
        </router-link>
        <v-spacer></v-spacer>

        <v-switch v-if="$vuetify.breakpoint.mdAndUp" v-model="contrast_mode" dark dense hide-details>
          <template v-slot:label>
            <small>{{ $t("contrastMode") }}</small>
          </template>
        </v-switch>

        <v-divider v-if="$vuetify.breakpoint.mdAndUp" vertical dark class="mx-3">
        </v-divider>

        <div class="d-none d-md-block d-lg-block">
          <template v-if="$store.getters.getUser.email">
            <v-menu nudge-bottom="5" offset-y left>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <small class="white--text">
                    {{ $t("loggedInAs") }}
                    {{ $store.getters.getUser.first_name }}
                    {{ $store.getters.getUser.preposition }}
                    {{ $store.getters.getUser.last_name }}
                  </small>
                  <v-icon dark>mdi-account-circle</v-icon>
                  <v-icon dark small>mdi-chevron-down</v-icon>
                </div>
              </template>
              <v-list dense>
                <v-list-item v-for="(menu_item, index) in dropdown_menu" :key="index" :to="menu_item.link">
                  <v-list-item-icon>
                    <v-icon v-text="menu_item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $t(menu_item.title)
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </div>
        <v-btn class="d-md-none" icon dark @click="drawer = !drawer">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </v-container>
    </v-app-bar>
  </div>
</template>

<script>
import menu from "../../mobile_menu.js";
import dropdown_menu from "../../dropdown_menu.js";

export default {
  data() {
    return {
      drawer: null,
      menu,
      dropdown_menu,
      contrast_mode: false,
    };
  },
  mounted() {
    this.contrast_mode = this.$store.getters.contrastMode;
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
  },
  watch: {
    contrast_mode: {
      handler() {
        this.$store.dispatch("setContrastMode", {
          contrast_mode: this.contrast_mode,
          currentVueComponent: this,
        });
      },
    },
    "$store.getters.contrastMode": {
      handler() {
        this.contrast_mode = this.$store.getters.contrastMode;
      },
    },
  },
};
</script>

<style scoped>
.drawer-button {
  position: fixed;
  top: 64px;
  left: -22px;
  z-index: 6;
}

.toolbar3-min-width {
  min-width: 130px;
}
</style>
