import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"widget",attrs:{"shaped":_vm.$config.shaped_widgets}},[_c(VCardTitle,{attrs:{"flat":""}},[_c(VCardTitle,{staticClass:"mb-0 py-0 font-weight-bold text--secondary"},[_vm._v("Veelgestelde vragen")]),_c(VSpacer),_c(VIcon,{staticClass:"align-right mr-3",attrs:{"x-large":""}},[_vm._v("mdi-comment-question-outline")])],1),_c(VCardText,[_c(VList,{attrs:{"dense":""}},[_c(VListItemGroup,{attrs:{"color":"primary"},model:{value:(_vm.selected_item),callback:function ($$v) {_vm.selected_item=$$v},expression:"selected_item"}},_vm._l((_vm.items),function(item,i){return _c(VListItem,{key:i},[_c(VListItemIcon,[_c(VIcon,{domProps:{"textContent":_vm._s(item.icon)}})],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(item.text)}})],1)],1)}),1)],1)],1),_c(VFooter,{attrs:{"dark":"","color":"primary"}},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","to":'profile',"small":""}},[_vm._v(" Uw profiel aanpassen "),_c(VIcon,{attrs:{"right":"","small":""}},[_vm._v("mdi-arrow-right")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }