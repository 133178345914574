<template>
  <v-dialog
    :fullscreen="$vuetify.breakpoint.mdAndDown"
    v-model="show"
    max-width="600"
  >
    <v-card>
      <v-toolbar flat color="primary" dark
        >{{ $t("barcode") }}
        <v-spacer></v-spacer>
        <v-btn icon @click="show = false"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>
      <v-card-text class="justify-center">
        <qr-code
          v-show="false"
          class="qr"
          :text="getToken"
          :size="150"
          color="black"
          bg-color="white"
          error-level="L"
        >
        </qr-code>

        <p class="mt-10 mx-3">{{ $t("barcodeHint") }}</p>

        <barcode
          class="center mt-7"
          :width="2"
          :value="getToken"
          format="CODE128"
        >
        </barcode>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import QrCode from "vue-qrcode-component";
import VueBarcode from "vue-barcode";

export default {
  data() {
    return {
      show: false,
    };
  },
  components: {
    QrCode,
    barcode: VueBarcode,
  },
  mounted() {
    this.show = this.$store.getters.showQrCodeModal;
  },
  computed: {
    getToken() {
      return this.$store.getters.selectedCard
        ? this.$store.getters.selectedCard.token
        : null;
    },
  },
  watch: {
    "$store.getters.showQrCodeModal": {
      handler() {
        this.show = this.$store.getters.showQrCodeModal;
      },
    },
    show: {
      handler() {
        this.$store.dispatch("setShowQrCodeModal", this.show);
      },
    },
  },
};
</script>

<style>
.center {
  width: 100%;
  text-align: center;
}

canvas {
  display: inline;
}
</style>