<template>
  <v-app :style="getBackground">
    <v-dialog
      hide-overlay
      transition="dialog-bottom-transition"
      fullscreen
      v-model="settings"
    >
      <v-card>
        <v-toolbar color="primary" dark>
          {{ $t("settings") }}
          <v-spacer></v-spacer>
          <v-btn icon @click="settings = false"
            ><v-icon dark>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text class="justify-center pt-3">
          <v-radio-group  hide-details v-model="language">
            <v-radio :label="$t('dutch')" value="nl"> </v-radio>
            <v-radio :label="$t('english')" value="en"> </v-radio>
          </v-radio-group>
          <v-switch
            v-model="contrast_mode"
            
            hide-details
            :label="$t('contrastMode')"
          >
          </v-switch>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-app-bar dense flat app color="primary">
      <a v-if="$config.return_url" style="text-decoration: none" :href="$config.return_url">
        <v-icon class="mr-1" small color="white">mdi-open-in-new</v-icon>
        <small class="white--text">
          {{ $t("backTo") }}
        </small>
      </a>
      <v-spacer></v-spacer>
      <template v-if="$vuetify.breakpoint.mdAndUp">
        <v-radio-group dense dark hide-details row v-model="language">
          <v-radio value="nl">
            <template v-slot:label>
              <small>{{ $t("dutch") }}</small>
            </template>
          </v-radio>
          <v-radio value="en">
            <template v-slot:label>
              <small>{{ $t("english") }}</small>
            </template>
          </v-radio>
        </v-radio-group>
        <v-divider dark vertical class="mx-3"></v-divider>
        <v-switch v-model="contrast_mode" dark dense hide-details>
          <template v-slot:label>
            <small>{{ $t("contrastMode") }}</small>
          </template>
        </v-switch>
      </template>
      <template v-else>
        <v-btn class="d-md-none" icon dark @click="settings = !settings">
          <v-icon>mdi-cog</v-icon>
        </v-btn>
      </template>
    </v-app-bar>
    <v-main>
      <v-container fill-height fluid>
        <router-view />
      </v-container>
    </v-main>

    <Footer v-if="$vuetify.breakpoint.mdAndUp" />
  </v-app>
</template>

<script>
import Footer from "./Footer.vue";
export default {
  name: "Home",
  components: { Footer },
  data() {
    return {
      contrast_mode: false,
      language: "nl",
      settings: false,
    };
  },
  created() {
    this.language = this.$store.getters.language;
  },
  methods: {},
  computed: {
    getBackground() {
      if (this.$config.background_image_url) {
        return (
          'background-image: url("' +
          this.$config.background_image_url +
          '");background-position: center center;background-size: cover'
        );
      }

      return (
        "background: linear-gradient(60deg," +
        this.$config.primary_color +
        " 50%," +
        this.$config.secondary_color +
        " 98%)"
      );
    },
  },
  watch: {
    contrast_mode: {
      handler() {
        this.$store.dispatch("setContrastMode", {
          contrast_mode: this.contrast_mode,
          currentVueComponent: this,
        });
      },
    },
    language: {
      handler() {
        this.$store.dispatch("setLanguage", {
          language: this.language,
          currentVueComponent: this,
        });
      },
    },
    "$store.getters.language": {
      handler() {
        this.language = this.$store.getters.language;
      },
    },
  },
};
</script>

<style lang="scss">
.Home {
  .v-app-bar {
    h2 {
      text-transform: uppercase;
    }
  }
}
</style>
