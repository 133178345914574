import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"fullscreen":_vm.$vuetify.breakpoint.mdAndDown,"transition":"dialog-top-transition","max-width":"800"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VToolbar,{attrs:{"flat":""}},[_c('h3',[_vm._v(" "+_vm._s(_vm.$t("depositAmount"))+" ")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.show = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,{staticClass:"justify-center"},[[_c('div',{staticClass:"pa-12"},[_c(VRow,[(_vm.$vuetify.breakpoint.mdAndUp)?_c(VCol,[_c(VImg,{staticClass:"mx-auto mt-3",attrs:{"width":"200","src":require("../assets/ideal.png")}})],1):_vm._e(),(_vm.mollieActive)?_c(VCol,[_c(VForm,{ref:"deposit_form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[(!_vm.printable_invoice)?_c(VRadioGroup,{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(_vm._s(_vm.$t("chooseAmount")))])]},proxy:true}],null,false,3204784986),model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}},[_c(VRadio,{attrs:{"value":15},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v("€ 15,-")])]},proxy:true}],null,false,1900744414)}),_c(VRadio,{attrs:{"value":20},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v("€ 20,-")])]},proxy:true}],null,false,1787283320)}),_c(VRadio,{attrs:{"value":30},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v("€ 30,-")])]},proxy:true}],null,false,3927678169)})],1):_vm._e(),_c(VTextField,{attrs:{"type":"number","label":_vm.$t('inputAmount', {
                                            max_amount: _vm.$config.max_deposit_amount,
                                        }),"rules":_vm.rules,"maxLength":"4","oninput":"javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}}),(_vm.extra_costs > 0)?_c(VAlert,{attrs:{"border":"left","outlined":""}},[_c('div',{staticClass:"text-center"},[_vm._v(" De transactiekosten voor deze betaling bedragen € "+_vm._s(_vm.formattedExtraCosts)+". ")])]):_vm._e()],1)],1):_c(VCol,{staticClass:"text-h7 my-auto"},[_c('span',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.$t("idealDisabledTitle")))]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.$t("idealDisabled")))])])],1)],1)],_c(VRow,[(_vm.mollieActive)?_c(VCol,{staticClass:"text-center text-h2"},[_c('span',{staticClass:"justify-center"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.totalAmount))+" ")]),_c(VBtn,{staticClass:"mt-9",attrs:{"rounded":"","disabled":!_vm.valid,"color":"primary","block":"","x-large":""},on:{"click":function($event){return _vm.submit()}}},[_vm._v(_vm._s(_vm.$t("addBalance")))])],1):_vm._e()],1)],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }