var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.gtm)?_c('noscript',[_c('iframe',{staticStyle:{"display":"none","visibility":"hidden"},attrs:{"src":'https://www.googletagmanager.com/ns.html?id=' + _vm.gtm,"height":"0","width":"0"}})]):_vm._e(),_c('Transition',{attrs:{"name":"fade"}},[(_vm.$route.name == 'login' ||
        _vm.$route.name == 'register' ||
        _vm.$route.name == 'password-reset' ||
        _vm.$route.name == 'change-password' ||
        _vm.$route.name == 'lost-card' ||
        _vm.$route.name == 'forgot-username' ||
        _vm.$route.name == 'public-carerequest' ||
        _vm.$route.name == 'verify-account' ||
        _vm.$route.name == 'offline' ||
        _vm.$route.name == 'verify-account-otp' ||
        _vm.$route.name == 'mfa-setup' ||
        _vm.$route.name == 'mfa-verify'
        )?_c('blank'):(_vm.$route.name == 'register-embed' ||
        _vm.$route.name == 'verify-account-otp-embed'
        )?_c('Embed'):_c('full')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }