<template>
  <v-row align="center" justify="center">
    <v-col>
      <v-card :color="$config.blurred_ui ? 'transparent' : 'white'" :dark="$config.fields_color_toggle"
        :class="$config.blurred_ui ? 'blurred-background' : null" :shaped="$config.shaped_widgets" class="pa-lg-5 mx-auto"
        flat max-width="600">
        <div class="text-left" v-if="!loggedIn">
          <router-link class="text-decoration-none text-center" to="login">
            <v-icon small>mdi-arrow-left</v-icon>
            {{ $t("gotoLogin") }}</router-link>
        </div>

        <div class="text-left mb-5" v-if="loggedIn">
          <router-link class="text-decoration-none text-center" to="dashboard">
            <v-icon small>mdi-arrow-left</v-icon>
            {{ $t("back") }}</router-link>
        </div>

        <v-card-title class="mx-5 mb-2 ml-0">{{ $t("lostCard") }}<br />
          {{ $config.app_name }}</v-card-title>

        <v-card-text>
          <p>
            {{ $t("lostCardIntro") }}
          </p>
          <template v-if="success">
            <v-alert outlined transition type="success" prominent border="left" color="success">
              {{ $t("lostCardSent") }}
            </v-alert>
          </template>

          <template v-if="!success">
            <v-alert v-if="error && !loading" outlined transition type="error" prominent border="left" color="error">
              {{ $t("somethingWentWrong") }}
            </v-alert>

            <v-form v-model="valid" ref="lost_card">
              <v-text-field :label="$t('firstName')" v-model="lost_card.firstname" :rules="required"></v-text-field>

              <v-text-field :label="$t('preposition')" v-model="lost_card.preposition"></v-text-field>

              <v-text-field :label="$t('lastName')" v-model="lost_card.lastname" :rules="required"></v-text-field>

              <v-text-field :label="$t('email')" v-model="lost_card.user_email" :rules="emailRules"></v-text-field>

              <address-lookup :address="lost_card"></address-lookup>

              <v-text-field :label="$t('enterToken')" v-model="lost_card.accesstoken" :rules="tokenRules"
                counter></v-text-field>

              <v-btn :rounded="$config.shaped_widgets" @click="submit()" block class="mt-5" color="primary" x-large
                :loading="loading">{{ $t("submitLostCardForm") }}</v-btn>
            </v-form>
          </template>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import AddressLookup from "../components/AddressLookup.vue";

export default {
  name: "lost-card",
  components: { AddressLookup },
  data() {
    return {
      valid: false,
      lost_card: {
        firstname: "",
        lastname: "",
        preposition: "",
        user_email: "",
        postal_code: "",
        housenumber: "",
        housenumber_addition: "",
        street: "",
        city: "",
        accesstoken: "",
      },
      required: [(v) => !!v || this.$t("mandatory")],
      emailRules: [
        (v) => !!v || this.$t("mandatory"),
        (v) =>
          !v ||
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            v
          ) ||
          this.$t("validEmail"),
      ],
      postcalCode: [
        (v) => !!v || this.$t("mandatory"),
        (v) =>
          !v ||
          /^(?:NL-)?(\d{4})\s*([A-Za-z]{2})$/.test(v) ||
          this.$t("invalidPostalCode"),
      ],
      tokenRules: [
        (v) => (v || "").length <= 19 || this.$t("nineteenCharacters"),
      ],
      loading: false,
      error: false,
      success: false,
      counter: 0,
      loggedIn: false,
    };
  },
  created() {
    this.userLoggedIn();
  },
  methods: {
    userLoggedIn() {
      this.loggedIn = this.$store.getters.isLoggedIn;
      if (this.loggedIn) {
        this.lost_card.accesstoken = this.$store.getters.selectedCard.token;
      }
    },
    submit() {
      this.success = false;
      this.error = false;
      this.$refs.lost_card.validate();

      if (this.valid) {
        let complete_address =
          this.lost_card.street +
          " " +
          this.lost_card.housenumber +
          " " +
          this.lost_card.housenumber_addition;

        this.loading = true;
        this.$http
          .post(this.$config.backend_url + "/lost-card", {
            user_email: this.lost_card.user_email,
            firstname: this.lost_card.firstname,
            preposition: this.lost_card.preposition,
            lastname: this.lost_card.lastname,
            address: complete_address,
            postal_code: this.lost_card.postal_code,
            city: this.lost_card.city,
            accesstoken: this.lost_card.accesstoken,
            program_data: {
              name: this.$config.name,
              email: this.$config.email,
              primary_color: this.$config.primary_color,
              url: this.$config.return_url,
            },
          })
          .then(() => {
            this.loading = false;
            this.success = true;
            this.error = false;
            this.$refs.lost_card.reset();
          })
          .catch((error) => {
            this.loading = false;
            this.error = true;
            this.error_response = error;
          });
      }
    },
  },
};
</script>

<style lang="scss">
.login-background {
  background: rgb(233, 63, 102);
  background: linear-gradient(60deg, #807779 50%, rgba(243, 150, 52, 1) 98%);
}

.uppercase input {
  text-transform: uppercase;
}
</style>
