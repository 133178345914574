<template>
  <v-footer app absolute color="accent" dark class="mt-lg-10 mt-md-5 mt-sm-3">
    <v-container>
      <div
        class="d-flex flex-column flex-sm-row align-center justify-center mt-4 overline"
      >
        <span
          >&#169; {{ $config.app_name }} {{ new Date().getFullYear() }}</span
        >
        <span class="mx-3">|</span
        ><a
          target="_blank"
          :href="$config.privacy_policy_url"
          class="text-decoration-none white--text text--lighten-3"
          >{{ $t("privacyPolicy") }}</a
        >
        <template v-if="$config.phone_number">
          <span class="mx-3">|</span>
          <v-icon class="mr-3" x-small>mdi-phone</v-icon>
          <span> {{ $config.phone_number }}</span>
        </template>

        <template v-if="$config.email">
          <span class="mx-3">|</span>
          <v-icon class="mr-3" x-small>mdi-email</v-icon>
          <a
            class="text-decoration-none white--text text--lighten-3"
            :href="'mailto:' + $config.email"
            >{{ $config.email }}</a
          >
        </template>
      </div>
    </v-container>
  </v-footer>
</template>

<script>
export default {};
</script>

<style></style>
