<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col cols="12" lg="6">
          <lost-card />
        </v-col>
        <v-col lg="6">
          <faq />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import Faq from "../components/widgets/Faq.vue";
import LostCard from "../components/widgets/LostCard.vue";
export default {
  components: { LostCard, Faq },
  name: "Balance",
};
</script>

<style lang="scss">
</style>
