<template>
  <v-card :shaped="$config.shaped_widgets" class="widget">
    <v-card-title flat>
      <v-card-title class="mb-0 py-0 font-weight-bold text--secondary">{{ $t("QRcode") }}</v-card-title>
      <v-spacer></v-spacer>
      <v-icon class="align-right mr-3" x-large>mdi-qrcode</v-icon>
    </v-card-title>

    <v-card-text style="padding-top: 0px">
      <qr-code
        class="qr"
        :text="getToken"
        :size="150"
        color="black"
        bg-color="white"
        error-level="L"
      >
      </qr-code>
    </v-card-text>
    <v-footer dark color="primary" class="pa-5"> </v-footer>
  </v-card>
</template>


<script>
import QrCode from "vue-qrcode-component";

export default {
  name: "QRcode",
  components: {
    QrCode,
  },
  computed: {
    getToken() {
      return this.$store.getters.selectedCard
        ? this.$store.getters.selectedCard.token
        : null;
    },
  },
};
</script>

<style>
.qr {
  padding: 0;
  margin: auto;
  display: block;
  width: 150px;
}
</style>