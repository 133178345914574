<template>
  <v-row align="center" justify="center">
    <v-col>
      <v-card :color="$config.blurred_ui ? 'transparent' : 'white'" :dark="$config.fields_color_toggle"
        :class="$config.blurred_ui ? 'blurred-background' : null" :shaped="$config.shaped_widgets"
        class="pa-lg-5 mx-auto" flat max-width="600">
        <div class="text-left">
          <router-link class="text-decoration-none text-center" to="login">
            <v-icon small>mdi-arrow-left</v-icon>
            {{ $t("gotoLogin") }}</router-link>
        </div>
        <v-card-title class="mx-5 mb-2 ml-0">{{ $t("createAccount") }} <br />
          {{ $config.app_name }}</v-card-title>
        <v-card-text>
          <v-alert v-if="$config.disable_registration" outlined transition type="warning" prominent border="left"
            color="warning">
            {{ $t("registrationDisabled") }}
          </v-alert>
          <template v-if="success">
            <v-alert outlined transition type="success" prominent border="left" color="success">
              {{ $t("activateAccountSuccessful") }}
            </v-alert>
          </template>
          <template v-if="!success">
            <v-alert v-if="error && !loading" outlined transition type="error" prominent border="left" color="error">
              {{ error_message }}
            </v-alert>
            <v-form v-model="valid" ref="activate_account" v-if="!$config.disable_registration">
              <v-text-field :label="$t('firstName')" v-model="activate_account.firstname"
                :rules="required"></v-text-field>

              <v-text-field :label="$t('preposition')" v-model="activate_account.preposition"></v-text-field>

              <v-text-field :label="$t('lastName')" v-model="activate_account.lastname"
                :rules="required"></v-text-field>

              <v-text-field filled :label="$t('postToken')" v-model="activate_account.post_token" :rules="tokenRules"
                counter class="numberinput" type="number"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                maxLength="7">
                <template v-slot:prepend>
                  <span style="white-space: nowrap">{{ preToken }}</span>
                </template>
              </v-text-field>

              <v-card-title class="ml-0 pl-0">
                {{ $t("accountInfo") }}
              </v-card-title>

              <v-switch v-model="show_username" :label="$t('showUsername')" class="mt-0"></v-switch>

              <transition name="fade">
                <v-text-field v-if="!show_username" :label="$t('username')" v-model="activate_account.username"
                  :hint="$t('changeUsernameNotPossible')" :rules="nameRules" class="lowercase"></v-text-field>
              </transition>

              <v-text-field :label="$t('emailAddress')" v-model="activate_account.email" :rules="emailRules"
                class="lowercase"></v-text-field>

              <password :label="$t('password')" v-model="activate_account.password"></password>

              <v-text-field :label="$t('confirmPassword')" v-model="password_repeat" :rules="confirmPasswordRules"
                :type="show_password ? 'text' : 'password'" :append-icon="!show_password ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show_password = !show_password"></v-text-field>

              <div v-if="available_newsletters.length > 0">
                <v-checkbox v-for="(newsletter, i) in available_newsletters" v-model="activate_account.newsletters"
                  :key="i" hide-details :value="newsletter.id" class="mb-8"><template v-slot:label>
                    <div>
                      {{ $t("subscribeToNewsletter") }} {{ newsletter.name }}
                    </div>
                  </template></v-checkbox>
              </div>

              <div v-if="$config.privacy_policy_url">
                <v-checkbox :rules="required">
                  <template v-slot:label>
                    <div>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <a target="_blank" :href="$config.privacy_policy_url" @click.stop v-on="on">
                            {{ $t("acceptPrivacyPolicy") }}
                          </a>
                        </template>
                        {{ $t("urlNewWindow") }}
                      </v-tooltip>
                    </div>
                  </template>
                </v-checkbox>
              </div>

              <v-btn :rounded="$config.shaped_widgets" @click="submit()" block class="primary" x-large
                :loading="loading">{{
        $t("register") }}</v-btn>
            </v-form>
          </template>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Password from "../components/Password.vue";
export default {
  name: "Login",
  components: { Password },
  data() {
    return {
      valid: false,
      show_password: false,
      activate_account: {
        firstname: "",
        lastname: "",
        preposition: "",
        email: "",
        username: null,
        password: "",
        accesstoken: "",
        post_token: "",
        newsletters: [],
        callback: null,
      },
      nameRules: [
        (v) => !!v || `${this.$t("isRequired")}`,
        (value) => (value && /^\S*$/.test(value)) || this.$t("noSpacesAllowed"),
      ],
      password_repeat: "",
      error_message: null,
      required: [(v) => !!v || this.$t("mandatory")],
      emailRules: [
        (v) => !!v || this.$t("mandatory"),
        (v) =>
          !v ||
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            v
          ) ||
          this.$t("validEmail"),
      ],
      confirmPasswordRules: [
        (value) => !!value || this.$t("confirmPassword"),
        (value) =>
          value === this.activate_account.password || this.$t("noMatch"),
      ],
      tokenRules: [
        (value) => !!value || this.$t("mandatory"),
        (v) => (v || "").length <= 7 || this.$t("sevenCharacters"),
      ],
      loading: false,
      error: false,
      success: false,
      counter: 0,
      show_username: true,
      available_newsletters: [],
      current_url: "",
      initializing: false,
      faulty_instance: false,
    };
  },
  created() {
    this.getNewsletters();
    var re = new RegExp(/^.*\//);
    this.current_url = re.exec(window.location.href)[0];
    this.initialize();
  },
  methods: {
    reloadCheck() {
      let last_visit = localStorage.getItem("last_visit");
      // if last_visit is not set, set it to current time
      if (!last_visit) {
        localStorage.setItem("last_visit", new Date().getTime());
      }

      // if last visit is longer than 1 day, reload the page
      if (new Date().getTime() - last_visit > 86400000) {
        localStorage.setItem("last_visit", new Date().getTime());
        window.location.reload();
      }

    },
    async initialize() {
      this.faulty_instance = false;
      this.initializing = true;
      this.reloadCheck()
      await this.getTranslations();
      await this.getInstanceSettings();
    },
    async getTranslations() {
      let url =
        process.env.VUE_APP_BACKEND_URL + "/translations"
      await this.$http.get(url, {
        headers: {
          "x-portal-instance-key": process.env.VUE_APP_INSTANCE_KEY,
        },
      })
        .then((response) => {
          if (response.data) {
            this.$store.dispatch("setTranslations", response.data);
            this.$i18n.setLocaleMessage("nl", response.data.nl);
            this.$i18n.setLocaleMessage("en", response.data.en);
          }
        })
        .catch(() => {
          this.initializing = false;
          this.faulty_instance = true;
        });

      return
    },
    async getInstanceSettings() {
      if (this.faulty_instance == true) {
        return
      }

      await this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "/instance-settings",
          {
            headers: {
              "x-portal-instance-key": process.env.VUE_APP_INSTANCE_KEY,
            },
          }
        )
        .then(async (response) => {
          this.$store.dispatch("setConfigVars", response.data);
          this.$store.dispatch("reset");

          this.initializing = false;
        })
        .catch(() => {
          this.initializing = false;
          this.faulty_instance = true;
        })

      return
    },
    getNewsletters() {
      this.$http
        .get(
          this.$config.backend_url + "/newsletters", {
          headers: {
            "x-portal-instance-key": process.env.VUE_APP_INSTANCE_KEY,
          },
        }
        )
        .then((response) => {
          this.available_newsletters = response.data;
        })
        .catch((error) => {
          this.error = true;
          this.error_response = error;
        });
    },
    submit() {
      this.$refs.activate_account.validate();

      if (this.valid) {
        this.loading = true;
        if (this.show_username) {
          this.activate_account.username = this.activate_account.email;
        }

        this.activate_account.accesstoken =
          this.$config.pre_token + this.activate_account.post_token;

        this.activate_account.callback = this.current_url + "login";

        this.$http
          .post(
            this.$config.backend_url + "/create-account",
            this.activate_account,
            {
              headers: {
                "x-portal-instance-key": process.env.VUE_APP_INSTANCE_KEY,
              },
            }
          )
          .then((response) => {
            this.loading = false;

            if (response.data.status == "FAILURE") {
              this.error = true;
              this.error_message = response.data.errorCode;
              this.success = false;
            }
            if (response.data.status == "SUCCESS") {
              this.error = false;
              this.error_message = "";
              this.success = true;

              // if this.$config.use_otp_verification is true, redirect to verify-account-otp
              if (this.$config.use_otp_verification) {
                this.$router.push({
                  name: "verify-account-otp",
                  query: {
                    username: this.activate_account.username,
                    email: this.activate_account.email,
                  },
                });
              }
            }
          })
          .catch((error) => {
            this.loading = false;
            this.error = true;
            this.error_response = error;
          });
      }
    },
  },
  computed: {
    preToken() {
      return this.$config.pre_token
        .replace(/[^\dA-Z]/g, "")
        .replace(/(.{4})/g, "$1 ")
        .trim();
    },
  },
  watch: {
    show_username: {
      handler() {
        this.activate_account.username = "";
      },
    },
  },
};
</script>

<style lang="scss">
.login-background {
  background: rgb(233, 63, 102);
  background: linear-gradient(60deg, #807779 50%, rgba(243, 150, 52, 1) 98%);
}

.lowercase input {
  text-transform: lowercase;
}

/* Chrome, Safari, Edge, Opera */
.numberinput input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.numberinput input[type="number"] {
  -moz-appearance: textfield;
}
</style>
