<template>
  <v-col lg="4" sm="6" xs="12" md="6" cols="12">
    <Transactions v-if="showWidget('Transactions')" />
    <Balance v-if="showWidget('Balance')" />
    <GroupitBalance v-if="showWidget('GroupitBalance')" />
    <Profile v-if="showWidget('Profile')" />
    <LostCard v-if="showWidget('LostCard')" />
    <ProlongCard v-if="showWidget('ProlongCard')" />
    <Faq v-if="showWidget('Faq')" />
    <InformalCare v-if="showWidget('InformalCare')" />
    <Info v-if="showWidget('Info')" />
    <QRcode v-if="showWidget('QRcode')" />
    <MyRewards v-if="showWidget('MyRewards')" />
    <Custom v-if="showWidget('Custom')" :url="url" :url_title="url_title" :title="title" :html="html"
      :image_url="image_url" />
    <BlockCard v-if="showWidget('BlockCard')" />
  </v-col>
</template>

<script>
import Balance from "./widgets/Balance.vue";
import GroupitBalance from "./widgets/GroupitBalance.vue";
import Transactions from "./widgets/Transactions.vue";
import Profile from "./widgets/Profile.vue";
import LostCard from "./widgets/LostCard.vue";
import ProlongCard from "./widgets/ProlongCard.vue";
import Faq from "./widgets/Faq.vue";
import InformalCare from "./widgets/InformalCare.vue";
import Info from "./widgets/Info.vue";
import QRcode from "./widgets/QRcode.vue";
import MyRewards from "./widgets/MyRewards.vue";
import Custom from "./widgets/Custom.vue";
import BlockCard from "./widgets/BlockCard.vue";

export default {
  props: ["position", "widget_name", "title", "url", "url_title", "html", "image_url"],
  components: {
    Transactions,
    Balance,
    GroupitBalance,
    Profile,
    LostCard,
    ProlongCard,
    Faq,
    InformalCare,
    Info,
    QRcode,
    MyRewards,
    Custom,
    BlockCard
  },

  methods: {
    showWidget(widget_name) {
      if (this.position == widget_name) {
        return true;
      }

      return false;
    },
  },
  computed: {
  },
};
</script>

<style>
</style>
