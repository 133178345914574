<template>
    <div>
        <div class="qr-code" v-if="status == 'generated'">
            <h2 class='mb-5'>{{ explanation }}</h2>
            <div class='qr-code-container'>
                <!-- qr code -->
                <vue-q-r-code-component :text="qr_code" :size="250"
                    :errorCorrectionLevel="'H'"></vue-q-r-code-component>
            </div>

            <!-- or -->
            <strong class="mt-5">{{ $t("Of voer de code handmatig in") }}</strong>

            <span class="secret-code">
                {{ parseCode(secret_code) }}
            </span>

            <h3 class="mt-5 mb-2 text-center">{{ textfield_label }}</h3>
            <v-text-field outlined v-if="!mfa_enabled" :label="textfield_label_short" v-model="mfa_code"
                :rules="required" :disabled="update_user_loading"></v-text-field>

            <v-btn rounded v-if="!mfa_enabled" @click="enableMFA()" color="primary">
                {{ $t("Ik heb de code ingevuld") }}</v-btn>

            <!-- i need help -->
            <div class="text-center mt-5">
                <v-btn text small @click="dialog = true">{{ $t("Hoe werkt dit?") }}</v-btn>
            </div>
        </div>
        <div v-if="status == 'idle'">
            <v-btn class="mt-5" rounded outlined block @click="getCode()" color="primary" :loading="loading">
                {{ $t("Twee-stapsverificatie instellen")
                }}</v-btn>
        </div>

        <!-- if loading -->
        <div v-if="status == 'loading'" class="d-block text-center justify-center mt-8">
            <div class="spin-loader"></div>
        </div>

        <!-- if status is verified -->

        <div v-if="status == 'verified'">
            <div class="align-center">
                <v-icon large class="align-center mb-5" color="success"
                    style="font-size:100px">mdi-check-circle-outline</v-icon>
            </div>
            <h3 class="text-center">
                {{ $t("Je hebt tweestapsverificatie succesvol ingesteld.") }}
            </h3>
            <div class="align-center">
                <!-- disable  -->
                <v-btn class="mt-7" rounded outlined @click="disableMFA()" color="primary">
                    {{ $t("Tweestapsverificatie uitschakelen") }}
                </v-btn>
            </div>
        </div>

        <v-dialog v-model="dialog" max-width="600px" rounded>
            <v-card>
                <v-card-title>
                    <span class="headline">{{ $t("Tweestapsverificatie") }}</span>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" icon @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <p>Om uw account beter te beveiligen, is het noodzakelijk om tweestapsverificatie in te stellen. Dit
                        houdt in dat je, naast jouw wachtwoord, een tweede vorm van verificatie nodig hebt om in te
                        loggen.
                        Hiervoor heb je een authenticator-app nodig.</p>
                    <p>Je kunt een van de volgende authenticator-apps gebruiken:</p>
                    <ul>
                        <li><strong>Google Authenticator</strong> - Beschikbaar voor <a
                                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                                target="_blank">Android</a> en <a
                                href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                                target="_blank">iOS</a>.</li>
                        <li><strong>Microsoft Authenticator</strong> - Beschikbaar voor <a
                                href="https://play.google.com/store/apps/details?id=com.azure.authenticator"
                                target="_blank">Android</a> en <a
                                href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458"
                                target="_blank">iOS</a>.</li>
                        <li><strong>Authy</strong> - Beschikbaar voor <a
                                href="https://play.google.com/store/apps/details?id=com.authy.authy"
                                target="_blank">Android</a>, <a href="https://apps.apple.com/us/app/authy/id494168017"
                                target="_blank">iOS</a>, en als desktopapplicatie.</li>
                    </ul>
                    <p class="mt-2">Volg na het downloaden van de app de instructies om tweestapsverificatie voor jouw
                        account in te
                        stellen. Dit zorgt voor een extra laag beveiliging en beschermt uw gegevens tegen onbevoegde
                        toegang.</p>

                    <!-- hulp nodig? Bel $config.email, $config.phone_number -->

                    <p class="mt-5">Heb je hulp nodig? Neem dan contact op met onze klantenservice via <a
                            :href="`mailto:${$config.email}`">{{ $config.email }}</a> of bel naar <a
                            :href="`tel:${$config.phone_number}`">{{ $config.phone_number }}</a>.</p>

                </v-card-text>
            </v-card>
        </v-dialog>

    </div>
</template>
<script>
import VueQRCodeComponent from 'vue-qrcode-component'
export default {
    name: "MFAConfiguration",
    components: {
        VueQRCodeComponent
    },
    data() {
        return {
            dialog: false,
            status: "idle",
            textfield_label_short: this.$t("6 cijferige code."),
            textfield_label: this.$t("Vul hier de 6 cijferige code uit jouw authenticator app in"),
            explanation: this.$t("Scan deze QR code met jouw authenticator app."),
            loading: false,
            mfa_code: "",
            mfa_enabled: false,
            secret_code: "",
            qr_code: "",
            update_user_loading: false,
            required: [v => !!v || this.$t('required')]
        }
    },
    created() {
        this.checkMFA();
    },
    methods: {
        checkMFA() {
            this.status = "loading";
            this.$http
                .get(this.$config.backend_url + "/check-mfa", {
                    headers: {
                        "x-portal-instance-key": process.env.VUE_APP_INSTANCE_KEY,
                    },
                })
                .then((response) => {
                    if (response.data.mfa_enabled) {
                        this.mfa_enabled = true;
                        this.status = "verified";
                    }
                    else
                        this.status = "idle";
                })
                .catch((error) => {
                    console.log(error);
                    this.status = "idle";
                });
        },
        disableMFA() {
            this.status = "loading";
            this.$http
                .post(this.$config.backend_url + "/disable-mfa", {}, {
                    headers: {
                        "x-portal-instance-key": process.env.VUE_APP_INSTANCE_KEY,
                    },
                })
                .then(() => {
                    this.mfa_enabled = false;
                    this.status = "idle";
                })
                .catch(() => {
                    this.mfa_enabled = true;
                    this.status = "verified";
                });
        },
        enableMFA() {
            this.status = "loading";

            this.$http
                .post(this.$config.backend_url + "/verify-mfa", { mfa_code: this.mfa_code, token: this.$route.query.token }, {
                    headers: {
                        "x-portal-instance-key": process.env.VUE_APP_INSTANCE_KEY,
                    },
                })
                .then((response) => {
                    this.mfa_enabled = true;
                    this.status = "verified";
                    this.$emit("mfa-enabled", response.data);
                })
                .catch(() => {
                    this.$toast.error(this.$t("De code is niet correct"));
                    this.mfa_enabled = false;
                    this.status = "idle";
                });
        },
        getCode() {
            this.status = "loading";

            this.$http
                .get(this.$config.backend_url + "/register-authenticator", {
                    headers: {
                        "x-portal-instance-key": process.env.VUE_APP_INSTANCE_KEY,
                        "Authorization": "Bearer " + localStorage.getItem("token"),
                    },
                })
                .then((response) => {
                    this.secret_code = response.data.secret_code;

                    let username = this.$route.query.username;

                    if (!username) {
                        username = this.$store.state.user.email;
                    }

                    this.qr_code = `otpauth://totp/${username}?secret=${this.secret_code}&issuer=${this.$config.name}`;
                    this.status = "generated";
                })
                .catch((error) => {
                    // if we get 422, we log the user out with a toast message
                    if (error.response.status == 422) {
                        this.$toast.error(this.$t("De token is verlopen. Log opnieuw in."));
                        this.$router.push({ name: "login" });
                    }
                    this.status = "idle";
                });
        },
    },
    computed: {
        parseCode() {
            // we make the this.secret_code more readable
            return (code) => {
                if (code) {
                    return code.match(/.{1,4}/g).join(" ");
                }
            };
        },
    },

}
</script>
<style lang="">

</style>
