<template>
  <v-app>
    <div class="mx-5 mt-5">
      <h2 class="mb-0"><strong>{{ $config.name }}</strong></h2>
      <p class="mx-5 mb-2 ml-0">{{ $t("Jouw account registreren") }}</p>
    </div>
    <div class="ma-5">
      <template v-if="status == 'idle' || status == 'error'">
        <p>
          {{ $t("Je hebt een e-mail ontvangen met een 6 cijferige code.") }}
          {{ $t("Vul deze code hieronder in om jouw account te verifiëren.") }}
          {{ $t("Heb je geen e-mail ontvangen?") }} <a :href="`mailto: ${$config.email}`">
            {{ $t("Neem contact op met de helpdesk") }}</a>.</p>

        <v-alert v-if="status == 'error'" outlined transition type="error" border="left" color="error">
          {{ error_message }}
        </v-alert>

        <v-form v-model="valid" ref="verify_account">
          <v-text-field v-model="otp_code" :label="$t('6 cijferige code')" filled :rules="otp_code_rules"
            :disabled="loading"></v-text-field>
        </v-form>

        <v-btn :rounded="$config.shaped_widgets" :loading="loading" :disabled="loading" @click="reset()" block
          class="primary" :x-large="$vuetify.breakpoint.mdAndUp">{{
            $t("Verzenden")
          }}</v-btn>
      </template>

      <template v-else-if="status == 'success'">

        <!-- large checkmark icon circle -->
        <div class="align-center">
          <v-icon large class="align-center mb-5" color="success"
            style="font-size:150px">mdi-check-circle-outline</v-icon>
        </div>
        <h3 class="text-center">
          {{ $t("Jouw account is nu geverifieerd. Je kunt inloggen met jouw gebruikersnaam en wachtwoord.") }}
        </h3>
        <p class="text-center mt-4"> {{ $t("Je kunt nu inloggen met jouw gebruikersnaam en wachtwoord!") }}</p>
      </template>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "PasswordReset",
  data() {
    return {
      valid: false,
      loading: false,
      status: "idle",
      otp_code: "",
      current_url: null,
      error_message: "",
      otp_code_rules: [
        (v) => !!v || this.$t("Dit veld is verplicht"),
        (v) => (v && v.length == 6) || this.$t("De code moet 6 cijfers bevatten"),
        (v) => (v && /^\d+$/.test(v)) || this.$t("De code mag alleen cijfers bevatten"),
      ],
    };
  },
  created() {
    var re = new RegExp(/^.*\//);
    this.current_url = re.exec(window.location.href)[0];
  },
  methods: {
    reset() {
      this.status = "idle";
      this.$refs.verify_account.validate();

      if (this.valid) {
        this.loading = true;
        this.$http
          .post(this.$config.backend_url + "/verify-otp", {
            otp_code: this.otp_code,
          }, {
            headers: {
              "x-portal-instance-key": process.env.VUE_APP_INSTANCE_KEY,
            },
          })
          .then(() => {
            this.loading = false;
            this.status = "success";
            this.$refs.verify_account.reset();

            // after 3 seconds, send event to parent window
            setTimeout(() => {
              window.parent.postMessage({
                event: "account_verified",
              }, this.current_url);
            }, 3000);
          })
          .catch((error) => {
            this.loading = false;
            this.status = "error";
            if (error.response.status == 422) {
              this.error_message = this.$t("De code is onjuist. Probeer het opnieuw.");
            } else {
              this.error_message = this.$t("Er is iets misgegaan. Probeer het opnieuw.");
            }
          });
      }
    },
  },

};
</script>

<style lang="scss">
</style>
