import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"widget",attrs:{"shaped":_vm.$config.shaped_widgets}},[_c(VCardTitle,{attrs:{"flat":""}},[_c(VCardTitle,{staticClass:"mb-0 py-0 font-weight-bold text--secondary"},[_vm._v(_vm._s(_vm.$t("QRcode")))]),_c(VSpacer),_c(VIcon,{staticClass:"align-right mr-3",attrs:{"x-large":""}},[_vm._v("mdi-qrcode")])],1),_c(VCardText,{staticStyle:{"padding-top":"0px"}},[_c('qr-code',{staticClass:"qr",attrs:{"text":_vm.getToken,"size":150,"color":"black","bg-color":"white","error-level":"L"}})],1),_c(VFooter,{staticClass:"pa-5",attrs:{"dark":"","color":"primary"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }