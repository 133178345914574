<template>
    <div>
        <noscript v-if="gtm"><iframe :src="'https://www.googletagmanager.com/ns.html?id=' + gtm" height="0" width="0"
                style="display: none; visibility: hidden"></iframe></noscript>
        <Transition name="fade">
            <blank v-if="$route.name == 'login' ||
            $route.name == 'register' ||
            $route.name == 'password-reset' ||
            $route.name == 'change-password' ||
            $route.name == 'lost-card' ||
            $route.name == 'forgot-username' ||
            $route.name == 'public-carerequest' ||
            $route.name == 'verify-account' ||
            $route.name == 'offline' ||
            $route.name == 'verify-account-otp' ||
            $route.name == 'mfa-setup' ||
            $route.name == 'mfa-verify'
            " />
            <!-- if register-embed -->
            <Embed v-else-if="$route.name == 'register-embed' ||
            $route.name == 'verify-account-otp-embed'
            " />
            <full v-else />
        </Transition>
    </div>
</template>

<script>
import Blank from "./layout/Blank.vue";
import Full from "./layout/Full.vue";
import Embed from "./layout/Embed.vue";
import "./style.scss";

export default {
    components: { Blank, Full, Embed },
    name: "App",
    created() {
        this.$i18n.locale = this.$store.getters.language;
        this.updateTranslations();
    },
    methods: {
        updateTranslations() {
            let url = process.env.VUE_APP_BACKEND_URL + "/translations";
            this.$http
                .get(url, {
                    headers: {
                        "x-portal-instance-key": process.env.VUE_APP_INSTANCE_KEY,
                    },
                })
                .then((response) => {
                    if (response.data) {
                        this.$store.dispatch("setTranslations", response.data);
                        this.$i18n.setLocaleMessage("nl", response.data.nl);
                        this.$i18n.setLocaleMessage("en", response.data.en);
                    }
                })
                .catch(() => {
                    this.initializing = false;
                    this.faulty_instance = true;
                });
        },
    },
    computed: {
        gtm() {
            return this.$config.gtm;
        },
    },
    watch: {
        "$store.getters.getConfigVars": {
            handler(val, oldVal) {
                if (val.updated_at != oldVal.updated_at) {
                    window.location.reload();
                }
            },
        },
    },
};
</script>

<style></style>
