<template>
  <v-card :shaped="$config.shaped_widgets" class="widget">
    <v-card-title flat>
      <v-card-title class="mb-0 py-0 font-weight-bold text--secondary">{{
          $t("informalCareWidget")
      }}</v-card-title>
      <v-spacer></v-spacer>
      <v-icon class="align-right mr-3" x-large>mdi-heart</v-icon>
    </v-card-title>

    <v-card-text>
      <loader v-if="loading"></loader>
      <template v-else>
        <v-data-table :headers="headers" :items="care_requests" :items-per-page="4" sort-desc sort-by="created_at"
          hide-default-footer dense class="adjusted-margin">
          <template v-slot:item.created_at="{ item }">
            <small> {{ item.created_at | moment("DD-MM-YYYY") }} </small>
          </template>

          <template v-slot:item.status="{ item }">
            <small> {{ $t(item.status) }} </small>
          </template>

          <template v-slot:item.approval_status="{ item }">
            <small> {{ $t(item.approval_status) }} </small>
          </template>
        </v-data-table>

      </template>
    </v-card-text>

    <v-footer dark color="primary" :class="loading ? 'pa-5' : ''">
      <v-spacer></v-spacer>
      <informal-care-request-modal v-if="!loading" :has_open_request="checkOpenRequest" @refresh="getCareRequests">
      </informal-care-request-modal>
    </v-footer>
  </v-card>
</template>

<script>
import InformalCareRequestModal from "../InformalCareRequestModal.vue";
export default {
  components: {
    InformalCareRequestModal,
  },
  data() {
    return {
      loading: false,
      selected_item: null,
      care_requests: [],
      headers: [
        {
          text: this.$t("createdAt"),
          align: "start",
          sortable: true,
          value: "created_at",
        },
        {
          text: this.$t("approvalStatus"),
          align: "start",
          sortable: true,
          value: "approval_status",
        },
      ],
    };
  },
  created() {
    this.getCareRequests();
  },
  methods: {
    getCareRequests() {
      this.loading = true;
      this.$http
        .get(this.$config.backend_url + "/informalcare-requests")
        .then((response) => {
          this.care_requests = response.data;
          this.loading = false;
        }).catch(() => {
          this.loading = false;
          this.$toast.error('Kon niet laden');
        });
    },
  },
  computed: {
    checkOpenRequest() {
      let has_open_request = false;

      this.care_requests.forEach((request) => {
        if (request.status == "FINISHED" && request.approval_status == "OPEN") {
          has_open_request = true;
        }
      });

      return has_open_request;
    },
  },
};
</script>

<style>
</style>
