export default [
  {
    title: "profile",
    link: "profile",
    icon: "mdi-account",
  },
  {
    title: "logout",
    link: "login",
    icon: "mdi-logout",
  },
];
