<template>
  <v-card :shaped="$config.shaped_widgets" class="widget">
    <v-card-title flat>
      <v-card-title class="mb-0 py-0 font-weight-bold text--secondary">Veelgestelde vragen</v-card-title>
      <v-spacer></v-spacer>
      <v-icon class="align-right mr-3" x-large>mdi-comment-question-outline</v-icon>
    </v-card-title>

    <v-card-text>
      <v-list dense>
        <v-list-item-group v-model="selected_item" color="primary">
          <v-list-item v-for="(item, i) in items" :key="i">
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card-text>
    <v-footer dark color="primary">
            <v-spacer></v-spacer>
      <v-btn text :to="'profile'" small>
        Uw profiel aanpassen
        <v-icon right small>mdi-arrow-right</v-icon>
      </v-btn>
    </v-footer>
  </v-card>
</template>


<script>
export default {
  data() {
    return {
      selected_item: null,
      items: [
        { text: "Hoe kan ik opwaarderen?", icon: "mdi-arrow-right" },
        { text: "Hoe voeg ik een nieuwe pas toe?", icon: "mdi-arrow-right" },
        { text: "Ik ben mijn pas verloren, wat nu?", icon: "mdi-arrow-right" },
        { text: "Hoe kan ik mijn tegoed doneren?", icon: "mdi-arrow-right" },
      ],
    };
  },
};
</script>

<style>
</style>