import Vue from "vue";
import Router from "vue-router";
import goTo from "vuetify/es5/services/goto";

Vue.use(Router);

const router = new Router({
    routes: [
        {
            path: "/dashboard",
            name: "dashboard",
            component: require("@/views/Dashboard").default,
            meta: {
                middleware: true,
                title: "dashboard",
                icon: "mdi-view-dashboard",
            },
        },
        {
            path: "/profile",
            name: "profile",
            component: require("@/views/Profile").default,
            meta: {
                middleware: true,
                title: "profile",
                icon: "mdi-account",
            },
        },
        {
            path: "/balance",
            name: "balance",
            component: require("@/views/Balance").default,
            meta: {
                middleware: true,
                title: "balance",
            },
        },
        {
            path: "/transactions",
            name: "transactions",

            component: require("@/views/Transactions").default,
            meta: {
                middleware: true,
                title: "transactions",
                icon: "mdi-cart",
            },
        },
        {
            path: "/check-payment",
            name: "check-payment",
            component: require("@/views/CheckPayment").default,
            meta: {
                middleware: true,
                title: "check-payment",
                icon: "mdi-cash",
            },
        },
        {
            path: "/block-card",
            name: "block-card",
            component: require("@/views/BlockCard").default,
            meta: {
                middleware: true,
                title: "block-card",
                icon: "mdi-block-helper",
            },
        },
        // NON AUTHENTICATED ROUTES
        {
            path: "/offline",
            name: "offline",
            component: require("@/views/Offline").default,
        },
        {
            path: "/login",
            name: "login",
            component: require("@/views/Login").default,
        },
        {
            path: "/register",
            name: "register",
            component: require("@/views/Register").default,
        },
        {
            path: "/register-embed",
            name: "register-embed",
            component: require("@/views/RegisterEmbed").default,
        },
        {
            path: "/password-reset",
            name: "password-reset",
            component: require("@/views/RequestPasswordReset").default,
        },
        {
            path: "/forgot-username",
            name: "forgot-username",
            component: require("@/views/ForgotUsername").default,
        },
        {
            path: "/change-password",
            name: "change-password",
            component: require("@/views/ChangePassword").default,
        },
        {
            path: "/lost-card",
            name: "lost-card",
            component: require("@/views/LostCard").default,
        },
        {
            path: "/verify-account",
            name: "verify-account",
            component: require("@/views/VerifyAccount").default,
        },
        {
            path: "/verify-account-otp",
            name: "verify-account-otp",
            component: require("@/views/VerifyAccountOTP").default,
        },
        {
            path: "/verify-account-otp-embed",
            name: "verify-account-otp-embed",
            component: require("@/views/VerifyAccountOTPEmbed").default,
        },
        // mfa verify and mfa setup
        {
            path: "/mfa-verify",
            name: "mfa-verify",
            component: require("@/views/MFAVerify").default,
        },
        {
            path: "/mfa-setup",
            name: "mfa-setup",
            component: require("@/views/MFASetup").default,
        },
        {
            path: "/public-carerequest",
            name: "public-carerequest",
            component: require("@/views/PublicCareRequestForm").default,
        },

        {
            path: "*",
            redirect: "/dashboard",
        },
    ],
    mode: "history",
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = 0;

        if (to.hash) {
            scrollTo = to.hash;
        } else if (savedPosition) {
            scrollTo = savedPosition.y;
        }

        return goTo(scrollTo);
    },
});

router.beforeEach((to, from, next) => {
    if (to.meta.middleware) {
        if (localStorage.getItem("token") !== null) {
            return next();
        } else {
            return next({ name: "login", query: { redirect: to.path } }); // redirect to login page if not logged in
        }
    } else {
        return next();
    }
});

export default router;
