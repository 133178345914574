<template>
  <v-card :shaped="$config.shaped_widgets" class="widget">
    <v-card-title class="mb-0" flat>
      <v-card-title class="mb-0 py-0 font-weight-bold text--secondary">{{
    $t("transactions")
  }}</v-card-title>
      <v-spacer></v-spacer>
      <v-icon class="align-right mr-3" x-large>mdi-cart</v-icon>
    </v-card-title>

    <v-card-text>
      <loader v-if="loading"></loader>
      <v-data-table v-else :headers="headers" :items="transactions" sort-desc sort-by="time" hide-default-footer dense
        class="adjusted-margin">
        <template v-slot:item.providerName="{ item }">
          <div v-if="item.providerName">
            <small :title="$t(item.providerName)">{{ getProviderName(item.providerName) }}</small>
          </div>
          <div v-else>
            <small>{{ $t("depositedAmount") }}</small>
          </div>
        </template>

        <template v-slot:item.amount="{ item }">
          <small>{{ item.amount | currency }}</small>
        </template>
        <template v-slot:item.time="{ item }">
          <small> {{ item.time | moment("l") }}</small>
        </template>
      </v-data-table>
    </v-card-text>
    <v-footer dark color="primary">
      <v-spacer></v-spacer>
      <v-btn text :to="'transactions'" small>
        {{ $t("allTransactions") }}
        <v-icon right small>mdi-arrow-right</v-icon>
      </v-btn>
    </v-footer>
  </v-card>
</template>

<script>
export default {
  components: {},
  name: "Transactions",
  data() {
    return {
      loading: false,
      headers: [
        {
          text: this.$t("type"),
          align: "start",
          sortable: true,
          value: "providerName",
        },
        {
          text: this.$t("amount"),
          sortable: true,
          value: "amount",
          align: "end",
        },
        {
          text: this.$t("date"),
          align: "end",
          sortable: true,
          value: "time",
        },
      ],
      transactions: [],
    };
  },
  mounted() {
    if (this.$store.getters.selectedCard) {
      this.getTransactions();
    }
  },
  methods: {
    getProviderName(providerName) {
      let name = this.$t(providerName)
      // shorten provider name
      if (name.length > 30) {
        return name.substring(0, 25) + "...";
      }
      return name;
    },
    getTransactions() {
      if (this.$store.getters.selectedCard.token) {
        this.loading = true;
        this.$http
          .get(
            this.$config.backend_url +
            "/transactions?token=" +
            this.$store.getters.selectedCard.token +
            "&size=4"
          )
          .then((response) => {
            this.transactions = response.data.slice(0, 4);
            this.loading = false;
          });
      } else {
        this.loading = false
      }
    },
  },
  watch: {
    "$store.getters.selectedCard": {
      handler() {
        this.getTransactions();
      },
    },
  },
};
</script>

<style lang="scss"></style>
