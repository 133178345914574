<template>
  <v-row>
    <v-col>
      <v-card :color="$config.blurred_ui ? 'transparent' : 'white'" :dark="$config.fields_color_toggle"
        :class="$config.blurred_ui ? 'blurred-background' : null" :shaped="$config.shaped_widgets" flat max-width="600"
        class="pa-lg-5 mx-auto">
        <div class="text-left">
          <router-link class="text-decoration-none text-center" to="login">
            <v-icon small>mdi-arrow-left</v-icon>
            {{ $t("gotoLogin") }}</router-link>
        </div>
        <v-card-title class="mx-5 mb-2 ml-0">{{ $t("changePassword") }} <br />
          {{ $config.app_name }}</v-card-title>
        <v-card-text>
          <v-alert v-if="success" outlined transition type="success" border="left" color="success" class="my-9">
            {{ $t("changePasswordRequested") }}
          </v-alert>
          <v-alert v-if="error" outlined transition type="error" border="left" color="error">
            {{ error_message }}
          </v-alert>
          <v-form v-if="!success" v-model="valid" ref="reset_password">
            <v-text-field v-model="username" :label="$t('username')" filled :rules="usernameRules"
              :disabled="loading"></v-text-field>
          </v-form>
          <v-btn :rounded="$config.shaped_widgets" v-if="!success" :loading="loading" :disabled="loading" @click="reset()"
            block class="primary" :x-large="$vuetify.breakpoint.mdAndUp">{{
              $t("restoreMyPassword")
            }}</v-btn>

          <div class="text-center mt-5">
            <router-link to="forgot-username">
              {{ $t("forgotUsername") }}</router-link>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "PasswordReset",
  data() {
    return {
      valid: false,
      loading: false,
      success: false,
      error: false,
      username: "",
      usernameRules: [(v) => !!v || this.$t("mandatory")],
      current_url: null,
      error_message: "",
    };
  },
  created() {
    var re = new RegExp(/^.*\//);
    this.current_url = re.exec(window.location.href)[0];
  },
  methods: {
    reset() {
      this.success = false;
      this.error = false;
      this.$refs.reset_password.validate();

      if (this.valid) {
        this.loading = true;
        this.$http
          .post(this.$config.backend_url + "/forgot-password", {
            username: this.username,
            callback: this.current_url + "change-password",
            program_data: {
              name: this.$config.name,
              email: this.$config.email,
              primary_color: this.$config.primary_color,
              url: this.$config.return_url,
              logo_url: this.$config.logo_url,
            },
          },
          {
            headers: {
              "x-portal-instance-key": process.env.VUE_APP_INSTANCE_KEY,
            }
          })
          .then(() => {
            this.loading = false;
            this.success = true;
            this.error = false;
            this.error_message = "";
            this.$refs.reset_password.reset();
          })
          .catch((error) => {
            this.loading = false;
            if (error.response.status == 404) {
              this.error_message = this.$t("userDoesNotExist");
            } else {
              this.error_message = error.response.message;
            }
            this.error = true;
            this.success = false;
          });
      }
    },
  },
};
</script>

<style lang="scss">
</style>
