<template>
    <v-dialog
        :fullscreen="$vuetify.breakpoint.mdAndDown"
        v-model="show"
        transition="dialog-top-transition"
        max-width="800"
    >
        <v-card>
            <v-toolbar flat>
                <h3>
                    {{ $t("depositAmount") }}
                </h3>
                <v-spacer></v-spacer>
                <v-btn icon @click="show = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-toolbar>
            <v-card-text class="justify-center">
                <template>
                    <div class="pa-12">
                        <v-row>
                            <v-col v-if="$vuetify.breakpoint.mdAndUp">
                                <v-img class="mx-auto mt-3" width="200" src="../assets/ideal.png"></v-img>
                            </v-col>
                            <v-col v-if="mollieActive">
                                <v-form ref="deposit_form" v-model="valid">
                                    <v-radio-group v-model="amount" v-if="!printable_invoice">
                                        <template v-slot:label>
                                            <div>{{ $t("chooseAmount") }}</div>
                                        </template>
                                        <v-radio :value="15">
                                            <template v-slot:label>
                                                <div>€ 15,-</div>
                                            </template>
                                        </v-radio>
                                        <v-radio :value="20">
                                            <template v-slot:label>
                                                <div>€ 20,-</div>
                                            </template>
                                        </v-radio>
                                        <v-radio :value="30">
                                            <template v-slot:label>
                                                <div>€ 30,-</div>
                                            </template>
                                        </v-radio>
                                    </v-radio-group>
                                    <v-text-field
                                        type="number"
                                        :label="
                                            $t('inputAmount', {
                                                max_amount: $config.max_deposit_amount,
                                            })
                                        "
                                        v-model="amount"
                                        :rules="rules"
                                        maxLength="4"
                                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                    ></v-text-field>
                                    <!-- message about extra costs -->
                                    <v-alert v-if="extra_costs > 0" border="left" outlined>
                                        <div class="text-center">
                                            De transactiekosten voor deze betaling bedragen € {{ formattedExtraCosts }}.
                                        </div>
                                    </v-alert>
                                </v-form>
                            </v-col>
                            <v-col class="text-h7 my-auto" v-else>
                                <span class="text-h6">{{ $t("idealDisabledTitle") }}</span
                                ><br />
                                <span>{{ $t("idealDisabled") }}</span>
                            </v-col>
                        </v-row>
                    </div>
                </template>
                <v-row>
                    <v-col class="text-center text-h2" v-if="mollieActive">
                        <span class="justify-center">
                            {{ totalAmount | currency }}
                        </span>
                        <v-btn
                            rounded
                            :disabled="!valid"
                            color="primary"
                            class="mt-9"
                            @click="submit()"
                            block
                            x-large
                            >{{ $t("addBalance") }}</v-btn
                        >
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data() {
        return {
            extra_costs: 0.0,
            amount: null,
            show: false,
            valid: false,
            rules: [
                (v) => !!v || this.$t("mandatory"),
                (v) => (v && v >= 5) || this.$t("amountHigherThan5"),
                (v) =>
                    (v && v <= this.maxAmount) ||
                    this.$t("amountLowerThanMax", {
                        max_amount: this.$config.max_deposit_amount,
                    }),
            ],
            current_url: null,
            printable_invoice: false,
        };
    },
    mounted() {
        this.show = this.$store.getters.showDepositModal;
    },
    created() {
        this.printable_invoice = this.$config.printable_invoice;
        this.amount = this.printable_invoice ? "" : 15;
        var re = new RegExp(/^.*\//);
        this.current_url = re.exec(window.location.href)[0];
    },
    beforeUpdate() {
        if (this.$store.getters.selectedCard) {
            this.getAdditionalCosts();
        }
    },
    methods: {
        getAdditionalCosts() {
            this.$http
                .get(
                    this.$config.backend_url +
                        "/transaction-costs?accesstoken=" +
                        this.$store.getters.selectedCard.token
                )
                .then((response) => {
                    this.extra_costs = parseFloat(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        submit() {
            this.$refs.deposit_form.validate();

            if (this.valid) {
                let payment = {
                    amount: parseFloat(this.amount).toFixed(2),
                    accesstoken: this.$store.getters.selectedCard.token,
                    redirect: this.current_url + "check-payment",
                };

                this.show = false;

                this.$http
                    .post(this.$config.backend_url + "/transaction", payment)
                    .then((response) => {
                        window.location.replace(response.data.redirectUrl);
                    })
                    .catch((error) => {
                        this.loading = false;
                        this.error = true;
                        this.error_response = error;
                    });
            }
        },
    },
    computed: {
        formattedExtraCosts() {
            return this.extra_costs.toFixed(2).replace(".", ",");
        },
        totalAmount() {
            return parseFloat(this.amount) + parseFloat(this.extra_costs);
        },
        maxAmount() {
            return parseInt(this.$config.max_deposit_amount);
        },
        mollieActive() {
            if (this.$config.allow_deposit == true) {
                return true;
            }
            return false;
        },
    },
    watch: {
        "$store.getters.showDepositModal": {
            handler() {
                this.show = this.$store.getters.showDepositModal;
            },
        },
        show: {
            handler() {
                this.$store.dispatch("setShowDepositModal", this.show);
            },
        },
    },
};
</script>

<style></style>
