import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"fullscreen":_vm.$vuetify.breakpoint.mdAndDown,"max-width":"600"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VToolbar,{attrs:{"flat":"","color":"primary","dark":""}},[_vm._v(_vm._s(_vm.$t("barcode"))+" "),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.show = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,{staticClass:"justify-center"},[_c('qr-code',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"qr",attrs:{"text":_vm.getToken,"size":150,"color":"black","bg-color":"white","error-level":"L"}}),_c('p',{staticClass:"mt-10 mx-3"},[_vm._v(_vm._s(_vm.$t("barcodeHint")))]),_c('barcode',{staticClass:"center mt-7",attrs:{"width":2,"value":_vm.getToken,"format":"CODE128"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }