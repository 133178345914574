<template>
  <div class="d-block text-center justify-center mt-8">
    <div class="spin-loader"></div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>