<template>
    <div>
        <v-btn text small @click="show = true">{{ $t("requestInformalCare") }}</v-btn>

        <v-dialog :fullscreen="$vuetify.breakpoint.mdAndDown" v-model="show" max-width="800" scrollable>
            <v-card
                :color="request.status == 'FINISHED' || has_open_request ? 'primary' : null"
                :dark="request.status == 'FINISHED' || has_open_request"
            >
                <v-toolbar flat color="primary" dark
                    >{{ $t("requestInformalCareSupport") }}
                    <v-spacer></v-spacer>
                    <v-btn
                        :loading="loading"
                        v-if="request.status != 'FINISHED' && !has_open_request"
                        small
                        outlined
                        dark
                        class="mr-3"
                        @click="submitRequest('DRAFT')"
                    >
                        <span v-if="$vuetify.breakpoint.mdAndUp"> {{ $t("submitAsDraft") }}</span>
                        <v-icon small :right="$vuetify.breakpoint.mdAndUp">mdi-content-save</v-icon>
                    </v-btn>
                    <v-btn icon @click="show = false"><v-icon>mdi-close</v-icon></v-btn>
                </v-toolbar>
                <v-card-text>
                    <div v-if="request.status == 'FINISHED' || has_open_request" class="success-message">
                        <span v-if="request_submitted">
                            <v-icon>mdi-check-circle-outline</v-icon><br />
                            {{ $t("requestSubmitted") }}
                        </span>

                        <span v-if="has_open_request && !request.sent_request">
                            <v-icon>mdi-close-circle-outline</v-icon><br />
                            {{ $t("noRequestAllowed") }}
                        </span>
                    </div>

                    <div v-else>
                        <v-stepper class="mt-5" flat v-model="step" vertical>
                            <v-stepper-step
                                :rules="[() => carereceiver_form]"
                                editable
                                :complete="step > 1"
                                step="1"
                                v-if="$config.show_carereceiver_address"
                            >
                                <h3>{{ $t("carereceiverRecords") }}</h3>
                            </v-stepper-step>

                            <v-stepper-content step="1" v-if="$config.show_carereceiver_address">
                                <v-card>
                                    <v-card-text>
                                        <p>{{ $t("carereceiverIntro") }}</p>
                                        <v-form ref="carereceiver_form">
                                            <v-text-field
                                                v-model="request.carereceiver.prefix"
                                                :label="$t('prefix')"
                                            ></v-text-field>
                                            <v-text-field
                                                class="required"
                                                v-model="request.carereceiver.first_name"
                                                :label="$t('firstName')"
                                                :rules="[required]"
                                                validate-on-blur
                                            ></v-text-field>
                                            <v-text-field
                                                v-model="request.carereceiver.preposition"
                                                :label="$t('preposition')"
                                            ></v-text-field>
                                            <v-text-field
                                                class="required"
                                                v-model="request.carereceiver.last_name"
                                                :label="$t('lastName')"
                                                :rules="[required]"
                                                validate-on-blur
                                            ></v-text-field>

                                            <date-picker
                                                v-if="$config.show_carereceiver_address"
                                                v-model="request.carereceiver.date_of_birth"
                                                :label="$t('dateOfBirth')"
                                            />

                                            <v-text-field
                                                v-if="$config.show_carereceiver_address"
                                                class="hide-arrows"
                                                v-model="request.carereceiver.phonenumber"
                                                :label="$t('phoneNumber')"
                                                :rules="phoneNumberFormat"
                                                v-mask="'##########'"
                                                validate-on-blur
                                                type="number"
                                            ></v-text-field>
                                            <v-text-field
                                                v-if="$config.show_carereceiver_address"
                                                v-model="request.carereceiver.email"
                                                :label="$t('email')"
                                                :rules="emailFormat"
                                                validate-on-blur
                                            ></v-text-field>
                                            <address-lookup
                                                v-if="$config.show_carereceiver_address"
                                                :address="request.carereceiver"
                                            ></address-lookup>
                                        </v-form>

                                        <v-btn
                                            rounded
                                            block
                                            class="mt-5"
                                            color="primary"
                                            @click="validateStep(2, 'carereceiver_form')"
                                        >
                                            {{ $t("next") }}
                                            <v-icon right>mdi-arrow-down</v-icon>
                                        </v-btn>
                                    </v-card-text>
                                </v-card>
                            </v-stepper-content>

                            <v-stepper-step
                                :rules="[() => caregiver_form]"
                                editable
                                :complete="step > ($config.show_carereceiver_address ? 2 : 1)"
                                :step="$config.show_carereceiver_address ? 2 : 1"
                            >
                                <h3>{{ $t("caregiverRecords") }}</h3>
                            </v-stepper-step>

                            <v-stepper-content :step="$config.show_carereceiver_address ? 2 : 1">
                                <v-card>
                                    <v-card-text>
                                        <p>{{ $t("caregiverIntro") }}</p>
                                        <v-form ref="caregiver_form">
                                            <v-text-field
                                                v-model="request.caregiver.prefix"
                                                :label="$t('prefix')"
                                            ></v-text-field>
                                            <v-text-field
                                                class="required"
                                                v-model="request.caregiver.first_name"
                                                :label="$t('firstName')"
                                                :rules="[required]"
                                                validate-on-blur
                                            ></v-text-field>
                                            <v-text-field
                                                v-model="request.caregiver.preposition"
                                                :label="$t('preposition')"
                                            ></v-text-field>
                                            <v-text-field
                                                class="required"
                                                v-model="request.caregiver.last_name"
                                                :label="$t('lastName')"
                                                :rules="[required]"
                                                validate-on-blur
                                            ></v-text-field>

                                            <date-picker
                                                v-model="request.caregiver.date_of_birth"
                                                :label="$t('dateOfBirth')"
                                            />

                                            <v-text-field
                                                class="hide-arrows"
                                                v-model="request.caregiver.phonenumber"
                                                :label="$t('phoneNumber')"
                                                :rules="phoneNumberFormat"
                                                validate-on-blur
                                                v-mask="'##########'"
                                            ></v-text-field>
                                            <v-text-field
                                                class="required"
                                                v-model="request.caregiver.email"
                                                :label="$t('email')"
                                                :rules="emailRules"
                                                validate-on-blur
                                            ></v-text-field>

                                            <address-lookup :address="request.caregiver"></address-lookup>

                                            <v-checkbox
                                                v-model="request.extension"
                                                :label="$t('careRequestExtension')"
                                            ></v-checkbox>
                                        </v-form>

                                        <v-btn
                                            rounded
                                            block
                                            class="mt-5"
                                            color="primary"
                                            @click="validateStep(3, 'caregiver_form')"
                                        >
                                            {{ $t("next") }}
                                            <v-icon right>mdi-arrow-down</v-icon>
                                        </v-btn>
                                    </v-card-text>
                                </v-card>
                            </v-stepper-content>

                            <v-stepper-step
                                :rules="[() => questions_form]"
                                editable
                                :complete="step > ($config.show_carereceiver_address ? 3 : 2)"
                                :step="$config.show_carereceiver_address ? 3 : 2"
                            >
                                <h3>{{ $t("informalCareSupport") }}</h3>
                            </v-stepper-step>

                            <v-stepper-content :step="$config.show_carereceiver_address ? 3 : 2">
                                <v-card>
                                    <v-card-text>
                                        <p>{{ $t("informalCareIntro") }}</p>
                                        <v-form ref="questions_form">
                                            <v-text-field
                                                class="required"
                                                v-model="request.questions.hours_per_week"
                                                :label="$t('hoursCarePerWeek')"
                                                type="number"
                                                min="0"
                                                :rules="[required]"
                                                validate-on-blur
                                                v-mask="'###'"
                                            ></v-text-field>
                                            <date-picker v-model="request.since_date" :label="$t('careSinceDate')" />
                                            <v-textarea
                                                class="required"
                                                v-model="request.questions.relationship"
                                                :label="$t('relationshipToCarereceiver')"
                                                :rules="[required]"
                                                validate-on-blur
                                                :hint="$t('relationshipToCarereceiverHint')"
                                            ></v-textarea>
                                            <v-textarea
                                                class="required"
                                                v-model="request.questions.caregiver_duties"
                                                :label="$t('caregiverDuties')"
                                                :rules="[required]"
                                                validate-on-blur
                                                :hint="$t('caregiverDutiesHint')"
                                            ></v-textarea>
                                            <v-textarea
                                                class="required"
                                                v-model="request.questions.carereceiver_clinical_picture"
                                                :label="$t('careReceiverClinicalPicture')"
                                                :rules="[required]"
                                                validate-on-blur
                                                :hint="$t('careReceiverClinicalPictureHint')"
                                            ></v-textarea>
                                        </v-form>

                                        <v-btn
                                            rounded
                                            block
                                            class="mt-5"
                                            color="primary"
                                            @click="validateStep(4, 'questions_form')"
                                        >
                                            {{ $t("next") }}
                                            <v-icon right>mdi-arrow-down</v-icon>
                                        </v-btn>
                                    </v-card-text>
                                </v-card>
                            </v-stepper-content>

                            <v-stepper-step
                                :rules="[() => terms_form]"
                                editable
                                :step="$config.show_carereceiver_address ? 4 : 3"
                            >
                                <h3>{{ $t("declarationAndConsent") }}</h3>
                            </v-stepper-step>
                            <v-stepper-content :step="$config.show_carereceiver_address ? 4 : 3">
                                <v-card>
                                    <v-card-text>
                                        <p>{{ $t("caregiverRequestText") }}</p>
                                        <v-form ref="terms_form">
                                            <v-text-field
                                                class="required"
                                                v-model="request.terms.place"
                                                :label="$t('signedAt')"
                                                :rules="[required]"
                                                validate-on-blur
                                            ></v-text-field>
                                            <date-picker
                                                v-model="request.terms.date"
                                                :label="$t('signedOn')"
                                                :rules="[required]"
                                                validate-on-blur
                                            />

                                            <v-checkbox
                                                v-model="request.filled_in_by"
                                                :label="$t('filledInByThirdLabel')"
                                            >
                                            </v-checkbox>

                                            <v-row v-if="request.filled_in_by">
                                                <v-col>
                                                    {{ $t("filledInByThirdText") }}
                                                    <v-text-field
                                                        class="required"
                                                        v-model="request.filled_in_by_name"
                                                        :label="$t('name')"
                                                        :rules="[required]"
                                                        validate-on-blur
                                                    ></v-text-field>
                                                    <v-text-field
                                                        class="hide-arrows"
                                                        v-model="request.filled_in_by_phone_number"
                                                        :label="$t('phoneNumber')"
                                                        :rules="phoneNumberFormat"
                                                        v-mask="'##########'"
                                                        validate-on-blur
                                                        type="number"
                                                    ></v-text-field>

                                                    <v-text-field
                                                        class="required"
                                                        v-model="request.filled_in_by_email"
                                                        :label="$t('email')"
                                                        :rules="emailRules"
                                                        validate-on-blur
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>

                                            <div v-if="$config.privacy_policy_url">
                                                <v-checkbox
                                                    v-model="request.terms.agreed_privacy_policy"
                                                    class="required"
                                                    :rules="[required]"
                                                >
                                                    <template v-slot:label>
                                                        <div>
                                                            <v-tooltip bottom>
                                                                <template v-slot:activator="{ on }">
                                                                    <a
                                                                        target="_blank"
                                                                        :href="$config.privacy_policy_url"
                                                                        @click.stop
                                                                        v-on="on"
                                                                    >
                                                                        {{ $t("agreeWithPrivacyPolicy") }}
                                                                    </a>
                                                                </template>
                                                                {{ $t("urlNewWindow") }}
                                                            </v-tooltip>
                                                        </div>
                                                    </template>
                                                </v-checkbox>
                                            </div>

                                            <v-checkbox
                                                v-if="hasTranslation('subscribeToCareNewsletter')"
                                                v-model="request.terms.newsletter"
                                                :label="$t('subscribeToCareNewsletter')"
                                            >
                                            </v-checkbox>
                                        </v-form>
                                        <v-btn
                                            rounded
                                            class="mt-5"
                                            x-large
                                            block
                                            color="primary"
                                            @click="submitRequest('FINISHED')"
                                        >
                                            {{ $t("submitRequest") }}
                                        </v-btn>
                                    </v-card-text>
                                </v-card>
                            </v-stepper-content>
                        </v-stepper>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import AddressLookup from "./AddressLookup.vue";
export default {
    components: { AddressLookup },
    props: ["has_open_request"],
    data() {
        return {
            loading: false,
            show: false,
            step: 1,
            error: false,
            required: (value) => !!value || this.$t("mandatory"),
            emailRules: [
                (v) => !!v || this.$t("mandatory"),
                (v) => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t("validEmail"),
            ],
            emailFormat: [(v) => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t("validEmail")],
            postcalCode: [
                (v) => !!v || this.$t("mandatory"),
                (v) => !v || /^(?:NL-)?(\d{4})\s*([A-Za-z]{2})$/.test(v) || this.$t("invalidPostalCode"),
            ],
            phoneNumberRules: [(v) => v.length == 10 || this.$t("incorrectPhonenumber")],
            phoneNumberFormat: [(v) => !v || v.length == 10 || this.$t("incorrectPhonenumber")],

            request: {
                carereceiver: {
                    prefix: "",
                    first_name: "",
                    preposition: "",
                    last_name: "",
                    date_of_birth: "",
                    phonenumber: "",
                    email: "",
                    postal_code: "",
                    housenumber: "",
                    housenumber_addition: "",
                    street: "",
                    city: "",
                },
                caregiver: {
                    prefix: "",
                    first_name: "",
                    preposition: "",
                    last_name: "",
                    date_of_birth: "",
                    phonenumber: "",
                    email: "",
                    postal_code: "",
                    housenumber: "",
                    housenumber_addition: "",
                    street: "",
                    city: "",
                },
                questions: {
                    relationship: "",
                    caregiver_duties: "",
                    carereceiver_clinical_picture: "",
                    hours_per_week: "",
                },
                terms: {
                    place: "",
                    date: "",
                    agreed_privacy_policy: false,
                    agreed_conditions: true,
                },
                status: "DRAFT",
                accesstoken: this.accesstoken,
                since_date: "",
            },
            request_submitted: false,
            saved_as_draft: false,
            error_on_save: false,
            loading_address: false,
            carereceiver_form: true,
            caregiver_form: true,
            questions_form: true,
            terms_form: true,
            filled_in_by: false,
            filled_in_by_email: "",
            filled_in_by_name: "",
            filled_in_by_phone_number: "",
            extension: false,
        };
    },
    created() {
        this.getUser();
    },
    methods: {
        getUser() {
            this.$http
                .get(this.$config.backend_url + "/user/participant")
                .then((response) => {
                    this.request.caregiver.first_name = response.data.firstName || "";
                    this.request.caregiver.preposition = response.data.preposition || "";
                    this.request.caregiver.last_name = response.data.lastName || "";
                    this.request.caregiver.email = response.data.email || "";
                    this.request.caregiver.postal_code = response.data.postalCode || "";
                    this.request.caregiver.housenumber = response.data.houseNumber || "";
                    this.request.caregiver.phonenumber = response.data.phoneNumber || "";
                })
                .catch((error) => {
                    this.error = true;
                    this.error_response = error;
                });
        },
        validateStep(targetStep, form) {
            this[form] = this.$refs[form].validate();
            if (this[form]) {
                if (this.$config.show_carereceiver_address) {
                    this.step = targetStep;
                } else {
                    // Aanpassen van de stapnummers als de eerste stap wordt overgeslagen
                    this.step = targetStep === 1 ? 2 : targetStep === 2 ? 3 : 4;
                }
            }
        },
        getPreviousRequest() {
            this.$http
                .get(this.$config.backend_url + "/informalcare-request")
                .then((response) => {
                    if (response.data.informalcare_request) {
                        this.request = response.data.informalcare_request;
                    }
                })
                .catch(() => {
                    this.loading = false;
                    this.error_on_save = true;
                });
        },
        validateForms() {
            let isCarereceiverFormValid = true;

            if (this.$config.show_carereceiver_address && this.$refs.carereceiver_form) {
                isCarereceiverFormValid = this.$refs.carereceiver_form.validate();
            }

            this.caregiver_form = this.$refs.caregiver_form.validate();
            this.questions_form = this.$refs.questions_form.validate();
            this.terms_form = this.$refs.terms_form.validate();

            if (isCarereceiverFormValid && this.caregiver_form && this.questions_form && this.terms_form) {
                return true;
            }

            return false;
        },

        submitRequest(status) {
            if (status == "FINISHED" && !this.validateForms()) {
                return this.$toast.error(this.$t("notAllFieldsWereFilled"));
            }
            this.error = false;
            this.request.status = status;
            this.request.accesstoken = this.accesstoken;
            this.loading = true;

            this.$http
                .post(this.$config.backend_url + "/informalcare-requests/create", this.request, {
                    headers: {
                        "x-portal-instance-key": process.env.VUE_APP_INSTANCE_KEY,
                    },
                })
                .then(() => {
                    this.loading = false;
                    if (this.request.status == "DRAFT") {
                        this.$toast.success(this.$t("savedAsDraft"));
                    }
                    if (this.request.status == "FINISHED") {
                        this.request_submitted = true;
                    }
                })
                .catch(() => {
                    this.loading = false;
                    this.error_on_save = true;
                });
        },
        hasTranslation(key) {
            return this.$t(key) !== key;
        },
    },
    computed: {
        accesstoken() {
            return this.$store.getters.selectedCard ? this.$store.getters.selectedCard.token : null;
        },
    },
    watch: {
        show() {
            if (this.show) {
                this.getPreviousRequest();
            } else {
                this.request_submitted = false;
                this.$emit("refresh");
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.success-message {
    min-height: 500px;
    line-height: 500px;
    text-align: center;
    span {
        display: inline-block;
        vertical-align: middle;
        line-height: normal;
        font-size: 30px;
        margin-left: 50px;
        margin-right: 50px;
        font-weight: 100;
        i {
            font-size: 150px;
            margin-bottom: 30px;
        }
    }
}
</style>
