<template>
  <v-card :shaped="$config.shaped_widgets" class="widget">
    <v-card-title flat>
      <v-card-title class="mb-0 py-0 font-weight-bold text--secondary">{{ $t("contactInfo") }}</v-card-title>
      <v-spacer></v-spacer>
      <v-icon class="align-right mr-3" x-large>mdi-information-variant</v-icon>
    </v-card-title>

    <v-card-text class="widget-height mx-4">
      <template v-if="$config.phone_number">
        <h4 class="my-3">
          <v-icon class="mr-3">mdi-phone</v-icon>
          {{ $config.phone_number }}
        </h4>
      </template>

      <template v-if="$config.email">
        <a
          class="text-decoration-none primary--text"
          :href="'mailto:' + $config.email"
        >
          <h4>
            <v-icon class="mr-3">mdi-email</v-icon>
            {{ $config.email }}
          </h4>
        </a>
      </template>
    </v-card-text>
    <v-footer dark color="primary" class="pa-5">
      <v-spacer></v-spacer>
    </v-footer>
  </v-card>
</template>


<script>
export default {
};
</script>