import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,[_c(VCol,[_c(VCard,{staticClass:"mx-auto",attrs:{"flat":"","max-width":"600"}},[_c('div',{staticClass:"text-left"},[_c('router-link',{attrs:{"to":"login"}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-arrow-left")]),_vm._v(" "+_vm._s(_vm.$t("gotoLogin"))+" ")],1)],1),_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.$t("Tweestapsverificatie instellen"))+" ")]),_c(VCardText,[_c('p',[_vm._v(_vm._s(_vm.intro))]),_c('MFAConfiguration',{on:{"mfa-enabled":_vm.redirect}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }