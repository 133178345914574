export default[
  {
    title: "dashboard",
    link: "dashboard",
    icon: "mdi-view-dashboard"
  }, {
    title : "transactions",
    link : "transactions",
    icon : "mdi-cart"
  }, {
    title : "profile",
    link : "profile",
    icon : "mdi-account"
  }, {
    title : "logout",
    link : "login",
    icon : "mdi-logout"
  },
];
