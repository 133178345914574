import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,[_c(VCardTitle,[_vm._v(_vm._s(_vm.$t("paymentStatus")))]),_c(VCardSubtitle),_c(VCardText,[(_vm.loading)?[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"primary"}})]:_vm._e(),(!_vm.loading && _vm.success)?[_c(VAlert,{attrs:{"outlined":"","transition":"","type":"success","prominent":"","border":"left","color":"success"}},[_vm._v(" "+_vm._s(_vm.paymentstatus)+" ")]),(_vm.printableInvoice)?_c(VCardText,[_c('p',[_vm._v(_vm._s(_vm.$t("printInvoiceText")))]),_c(VBtn,{attrs:{"color":"primary","outlined":""},on:{"click":_vm.redirectTransactions}},[_vm._v(_vm._s(_vm.$t("goToTransactions")))])],1):_vm._e()]:_vm._e(),(!_vm.loading && !_vm.success)?[_c(VAlert,{attrs:{"outlined":"","transition":"","type":"error","prominent":"","border":"left","color":"error"}},[_vm._v(" "+_vm._s(_vm.paymentstatus ? _vm.paymentstatus : _vm.$t("somethingWentWrong"))+" ")])]:_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }