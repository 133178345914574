<template>
  <v-row>
    <v-col>
      <div class="offline-message">
        <span>
          <v-icon color="white">mdi-close-network</v-icon><br />
          <div class="mt-6"> {{ $t("offline") }}</div>
          <v-btn @click="$router.back()" dark class="mt-5" text>
            <v-icon left>mdi-refresh</v-icon>
            {{ $t("back") }}
          </v-btn>
        </span>

      </div>
    </v-col>
  </v-row>
</template>


<script>
export default {
  name: "Offline",
  data() {
    return {
      is_online: false,
      interval: null,
    };
  },
  created() {
    this.checkOnline();
  },
  methods: {
    checkOnline() {
      this.interval = setInterval(() => {
        this.$http
          .get(process.env.VUE_APP_BACKEND_URL + "/instance-settings",
            {
              headers: {
                "x-portal-instance-key": process.env.VUE_APP_INSTANCE_KEY,
              },
            }
          )
          .then(() => {
            this.is_online = true;
            clearInterval(this.interval);
            this.$router.push({ name: "login" });
          })
          .catch(() => {
            this.is_online = false;
          });
      }, 5000);
    },
  },
};
</script>

<style lang="scss" scoped>
.offline-message {
  text-align: center;
  color: white;

  span {
    background: rgba(0, 0, 0, 0.5);
    padding: 50px;
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
    font-size: 30px;
    margin-left: 50px;
    margin-right: 50px;
    font-weight: 100;
    max-width: 800px;
    border-radius: 10px;

    i {
      font-size: 150px;
    }
  }
}
</style>
