<template>
  <v-row>
    <v-col>
      <v-text-field
        class="required"
        v-mask="'##-##-####'"
        :rules="[required, ruleValidDate]"
        v-model="date"
        placeholder="DD-MM-JJJJ"
        :label="$t(label)"
      >
      </v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
export default {
  props: ["value", "label"],
  data() {
    return {
      required: (value) => !!value || this.$t("mandatory"),
      ruleValidDate: (value) =>
        !value || this.dateNotValid || this.$t("dateNotValid"),
      date: null,
    };
  },
  created() {
    if (!this.date) {
      let date = moment(this.value);
      if (date.isValid()) {
        this.date = date.format("DD-MM-YYYY");
      }
    }
  },
  methods: {},
  computed: {
    dateNotValid() {
      if (this.date.length == 10 && moment(this.date, "DD-MM-YYYY").isValid()) {
        return true;
      }

      return false;
    },
  },
  watch: {
    value() {
      if (!this.date) {
        let date = moment(this.value);
        if (date.isValid()) {
          this.date = date.format("DD-MM-YYYY");
        }
      }
    },
    date() {
      this.$emit("input", this.date);
    },
  },
};
</script>

<style>
</style>