import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"widget",attrs:{"shaped":_vm.$config.shaped_widgets}},[_c(VCardTitle,{attrs:{"flat":""}},[_c(VCardTitle,{staticClass:"mb-0 py-0 font-weight-bold text--secondary"},[_vm._v("Pas verlengen")]),_c(VSpacer),_c(VIcon,{staticClass:"align-right mr-3",attrs:{"x-large":""}},[_vm._v("mdi-credit-card")])],1),_c(VCardText,{staticClass:"widget-height"},[_c('div',{staticClass:"widget-text"},[_vm._v(" Ipsum dolor sit amet consectetur adipiscing elit duis tristique. Adipiscing elit duis tristique sollicitudin. Neque laoreet suspendisse interdum consectetur libero id faucibus nisl tincidunt. ")])]),_c(VFooter,{attrs:{"dark":"","color":"primary"}},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","to":'informal-care',"small":""}},[_vm._v(" Mijn pas verlengen "),_c(VIcon,{attrs:{"right":"","small":""}},[_vm._v("mdi-arrow-right")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }