<template>
  <v-card :shaped="$config.shaped_widgets" class="widget">
    <v-card-title flat>
      <v-card-title class="mb-0 py-0 font-weight-bold text--secondary">{{
          $t("myRewards")
      }}</v-card-title>
      <v-spacer></v-spacer>
      <v-icon class="align-right mr-3" x-large>mdi-gift</v-icon>
    </v-card-title>

    <v-card-text style="padding-top: 20px !important">
      <loader v-if="loading"></loader>
      <template v-else>
        <div class="d-block text-center justify-center">
          <a :href="myrewards_url" target="_blank" rel="noreferrer noopener" class="primary--text"
            style="text-decoration: none">
            <span class="font-weight-bold text-h3">
              {{ myrewards_points }}
            </span>
            <p class="text-h5 black--text">punten</p>
          </a>
        </div>
        <div class="row text-center justify-center mt-8">
          <div class="d-block text-center justify-center px-9">
            <div>
              <a :href="mystampcards_url" target="_blank" rel="noreferrer noopener" class="primary--text"
                style="text-decoration: none">
                <span class="font-weight-bold text-h5">
                  {{ myrewards_stampcards }}
                </span>
                <p class="font-weight-bold black--text">stempelkaarten</p>
              </a>
            </div>
          </div>
          <div class="d-block text-center justify-center px-9">
            <a :href="mysurprises_url" target="_blank" rel="noreferrer noopener" class="primary--text"
              style="text-decoration: none">
              <span class="font-weight-bold text-h5">
                {{ myrewards_surprises }}
              </span>
              <p class="font-weight-bold black--text">verassingen</p>
            </a>
          </div>
        </div>
      </template>
    </v-card-text>
    <v-footer dark color="primary">
      <v-spacer></v-spacer>
      <v-btn text :href="myrewards_url" target="_blank" rel="noreferrer noopener" small>
        {{ $t("toMyRewards") }}

        <v-icon right small>mdi-launch</v-icon>
      </v-btn>
    </v-footer>
  </v-card>
</template>

<script>

export default {
  data() {
    return {
      myrewards_points: 0,
      myrewards_surprises: 0,
      myrewards_stampcards: 0,
      myrewards_url: null,
      mysurprises_url: null,
      mystampcards_url: null,
      loading: false,
      polling: null,
    };
  },
  created() {
    this.runPoll()
  },
  beforeDestroy () {
	clearInterval(this.polling)
  },
  methods: {
    async getUser() {
      let getUser = await this.$http.get(this.$config.backend_url + "/user").then((response) => {
        if (response.data.myrewards_points) {
          this.myrewards_points = response.data.myrewards_points;
          this.myrewards_surprises = response.data.myrewards_surprises;
          this.myrewards_stampcards = response.data.myrewards_stampcards;
          this.myrewards_url = response.data.myrewards_url;
          this.mysurprises_url = response.data.mysurprises_url;
          this.mystampcards_url = response.data.mystampcards_url;
        }
        return response;
      }).catch((error) => {
        console.log(error);
      })

      return getUser
    },
    runPoll() {
      let current = this
      async function run() {
        await current.getUser();
      }

      this.polling = setInterval(() => {
        run();
      }, 3000)
    }
  },
};
</script>

<style>
</style>
