import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"widget",attrs:{"shaped":_vm.$config.shaped_widgets}},[_c(VCardTitle,{staticClass:"mb-0",attrs:{"flat":""}},[_c(VCardTitle,{staticClass:"mb-0 py-0 font-weight-bold text--secondary"},[_vm._v(_vm._s(_vm.$t("groupitBalance")))]),_c(VSpacer),_c(VIcon,{staticClass:"align-right mr-3",attrs:{"x-large":""}},[_vm._v("mdi-credit-card")])],1),_c(VCardText,[(_vm.loading)?_c('loader'):_vm._e(),(!_vm.loading && _vm.balances.length == 0)?_c('div',{staticClass:"text-h7 widget-text"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("noGroupitBalanceMessage")))])]):_vm._e(),(!_vm.loading && _vm.balances.length > 0)?_c(VDataTable,{staticClass:"adjusted-margin",attrs:{"headers":_vm.headers,"items":_vm.balances,"sort-asc":"","sort-by":"service_name","hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item.serviceName",fn:function({ item }){return [(_vm.$vuetify.breakpoint.md)?_c('small',{staticClass:"shorten"},[_vm._v(_vm._s(_vm.$t(item.service_name)))]):_c('small',{staticClass:"shorten"},[_vm._v(_vm._s(_vm.$t(item.serviceName)))])]}},{key:"item.serviceBalance",fn:function({ item }){return [_c('small',[_vm._v(_vm._s(item.serviceBalance)+" "+_vm._s(item.serviceUnit))])]}}],null,false,1158882953)}):_vm._e()],1),_c(VFooter,{staticClass:"pa-5",attrs:{"dark":"","color":"primary"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }