<template>
  <v-card :shaped="$config.shaped_widgets" class="widget">
    <v-card-title class="mb-0" flat>
      <v-card-title class="mb-0 py-0 font-weight-bold text--secondary">{{
          $t("groupitBalance")
      }}</v-card-title>
      <v-spacer></v-spacer>
      <v-icon class="align-right mr-3" x-large>mdi-credit-card</v-icon>
    </v-card-title>

    <v-card-text>
      <loader v-if="loading"></loader>
      <div v-if="!loading && balances.length == 0" class="text-h7 widget-text">
        <span> {{ $t("noGroupitBalanceMessage") }}</span>
      </div>
      <v-data-table v-if="!loading && balances.length > 0" :headers="headers" :items="balances" sort-asc
        sort-by="service_name" hide-default-footer dense class="adjusted-margin">
        <template v-slot:item.serviceName="{ item }">
          <small class="shorten" v-if="$vuetify.breakpoint.md">{{
              $t(item.service_name)
          }}</small>
          <small class="shorten" v-else>{{ $t(item.serviceName) }}</small>
        </template>
        <template v-slot:item.serviceBalance="{ item }">
          <small>{{ item.serviceBalance }} {{ item.serviceUnit }}</small>
        </template>
      </v-data-table>
    </v-card-text>
    <v-footer class="pa-5" dark color="primary"> </v-footer>
  </v-card>
</template>

<script>
export default {
  components: {},
  name: "GroupitBalance",
  data() {
    return {
      loading: false,
      show_message: false,
      headers: [
        {
          text: this.$t("provisioning"),
          align: "start",
          sortable: true,
          value: "serviceName",
        },
        {
          text: this.$t("amount"),
          sortable: true,
          value: "serviceBalance",
          align: "start",
        },
      ],
      balances: [],
    };
  },
  created() {
    if (this.$store.getters.selectedCard) {
      this.getGroupitBalances();
    }
  },
  methods: {
    getGroupitBalances() {
      if (this.$store.getters.selectedCard.token) {
        this.loading = true;
        this.$http
          .get(
            this.$config.backend_url +
            "/groupit-card-balances/" +
            this.$store.getters.selectedCard.token
          )
          .then((response) => {
            this.balances = response.data;
          }).finally(() => {
            this.loading = false;
          });
      }
    },
  },
  watch: {
    "$store.getters.selectedCard": {
      handler() {
        this.getGroupitBalances();
      },
    },
  },
};
</script>

<style lang="scss">
.shorten {
  width: 150px;
  overflow: hidden;
  position: relative;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
