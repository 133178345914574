<template>
  <v-row>
    <v-col>
      <v-card :color="$config.blurred_ui ? 'transparent' : 'white'" :dark="$config.fields_color_toggle"
        :class="$config.blurred_ui ? 'blurred-background' : null" :shaped="$config.shaped_widgets" flat max-width="600"
        class="pa-lg-5 mx-auto">
        <div class="text-left">
          <router-link class="text-decoration-none text-center" to="login">
            <v-icon small>mdi-arrow-left</v-icon>
            <span class="text-primary">{{ $t("gotoLogin") }}</span></router-link>
        </div>
        <v-card-title class="mx-5 mb-2 ml-0">{{ $t("setPassword") }} <br />
          {{ $config.app_name }}</v-card-title>
        <v-card-text>
          <v-alert v-if="success" outlined transition type="success" border="left" color="success" class="my-9">
            {{ $t("yourPasswordWasChanged") }}
          </v-alert>
          <v-alert v-if="error" outlined transition type="error" border="left" color="error">
            {{ $t("incorrectToken") }}
          </v-alert>
          <v-form v-if="!success" v-model="valid" ref="change_password">
            <v-text-field v-model="username" :label="$t('username')" :rules="usernameRules"
              :disabled="loading"></v-text-field>

            <password :label="$t('newPassword')" v-model="password"></password>

            <v-text-field :label="$t('confirmPassword')" :type="show_password ? 'text' : 'password'"
              v-model="password_confirmation" :rules="confirmPasswordRules" :disabled="loading"></v-text-field>
          </v-form>
          <v-btn class="mt-3" v-if="!success" :loading="loading" :disabled="loading" @click="reset()" block
            color="primary" x-large :rounded="$config.shaped_widgets">{{ $t("savePassword") }}</v-btn>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Password from "../components/Password.vue";
export default {
  name: "PasswordReset",
  components: { Password },
  data() {
    return {
      valid: false,
      loading: false,
      success: false,
      error: false,
      username: "",
      usernameRules: [(v) => !!v || this.$t("mandatory")],
      password: "",
      password_confirmation: "",
      show_password: false,
      passwordRules: [
        (value) => (value || "").length >= 8 || this.$t("eightCharacters"),
        (value) => !!value || this.$t("mandatory"),
        (value) =>
          (value &&
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]{6,}$/.test(
              value
            )) ||
          this.$t("passwordRules"),
      ],
      confirmPasswordRules: [
        (value) => !!value || this.$t("confirmPassword"),
        (value) => value === this.password || this.$t("noMatch"),
      ],
      current_url: null,
      error_message: this.$t("incorrectToken"),
    };
  },
  created() {
    var re = new RegExp(/^.*\//);
    this.current_url = re.exec(window.location.href)[0];
  },
  methods: {
    reset() {
      this.success = false;
      this.error = false;
      this.$refs.change_password.validate();

      if (this.valid) {
        this.loading = true;
        this.$http
          .post(this.$config.backend_url + "/reset-password", {
            username: this.username,
            password: this.password,
            password_confirmation: this.password_confirmation,
            token: this.$route.query.token,
          })
          .then(() => {
            this.loading = false;
            this.success = true;
            this.error = false;
            this.$refs.change_password.reset();
          })
          .catch(() => {
            this.loading = false;
            this.error = true;
            this.success = false;
          });
      }
    },
  },
};
</script>

<style lang="scss">
</style>
