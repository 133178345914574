<template>
  <v-card :shaped="$config.shaped_widgets" class="widget">
    <v-card-title flat>
      <v-card-title class="mb-0 py-0 font-weight-bold text--secondary">{{ $t("balance") }}</v-card-title>
      <v-spacer></v-spacer>
      <v-icon class="align-right mr-3" x-large>mdi-currency-eur</v-icon>
    </v-card-title>

    <v-card-text class="widget-height">
      <h1 class="d-block text-center justify-center mt-8 font-weight-bold text-h3">
        {{ balance | currency }}
      </h1>
    </v-card-text>
    <v-footer dark color="primary" :class="mollieActive ? '' : 'pa-5'">
      <v-spacer></v-spacer>
      <v-btn v-if="mollieActive" text @click="$store.dispatch('setShowDepositModal', true)" small>
        {{ $t("addToBalance") }}
        <v-icon right small>mdi-arrow-right</v-icon>
      </v-btn>
    </v-footer>
  </v-card>
</template>


<script>
export default {
  data() {
    return {
      top_up_amount: 0,
    };
  },
  computed: {
    balance() {
      return this.$store.getters.selectedCard
        ? this.$store.getters.selectedCard.balance
        : 0;
    },
    mollieActive() {
      if (this.$config.allow_deposit == true) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style>
.top-up-section {
  width: 200px;
  display: inline-block;
}
</style>
