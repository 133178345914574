<template>
  <v-row>
    <v-col>
      <v-card :color="$config.blurred_ui ? 'transparent' : 'white'" :dark="$config.fields_color_toggle"
        :class="$config.blurred_ui ? 'blurred-background' : null" :shaped="$config.shaped_widgets" flat max-width="600"
        class="pa-lg-5 mx-auto">
        <div class="text-left">
          <router-link class="text-decoration-none text-center" to="login">
            <v-icon small>mdi-arrow-left</v-icon>
            {{ $t("gotoLogin") }}</router-link>
        </div>
        <v-card-title class="mx-5 ml-0">
          <span>Tweestapsverificatie (2FA) code invoeren</span>
          <v-spacer></v-spacer>

        </v-card-title>

        <v-card-text>
          <template v-if="status == 'idle' || status == 'error'">
            <p>
              {{ $t("Vul hier jouw 2FA code in.") }}</p>

            <v-alert v-if="status == 'error'" outlined transition type="error" border="left" color="error">
              {{ error_message }}
            </v-alert>
            <v-form v-model="valid" ref="verify_account">
              <v-text-field v-model="mfa_code" :label="$t('6 cijferige code')" filled :rules="mfa_rules"
                :disabled="loading"></v-text-field>
            </v-form>

            <v-btn :rounded="$config.shaped_widgets" :loading="loading" :disabled="loading" @click="send()" block
              class="primary" :x-large="$vuetify.breakpoint.mdAndUp">{{
                $t("Verzenden")
              }}</v-btn>

            <v-btn block class="mt-4" text small @click="dialog = true">{{ $t("Hulp nodig?") }}</v-btn>
          </template>

          <template v-else-if="status == 'success'">

            <!-- large checkmark icon circle -->
            <div class="align-center">
              <v-icon large class="align-center mb-5" color="success"
                style="font-size:150px">mdi-check-circle-outline</v-icon>
            </div>
            <h3 class="text-center">
              {{ $t("Verificatie gelukt, je wordt ingelogd") }}
            </h3>
            <p class="text-center mt-4"> {{ $t("Je wordt nu doorgestuurd naar de inlogpagina.") }}</p>
          </template>
        </v-card-text>
      </v-card>



      <v-dialog v-model="dialog" max-width="600px" rounded>
        <v-card>
          <v-card-title>
            <span class="headline">{{ $t("Tweestapsverificatie") }}</span>
            <v-spacer></v-spacer>
            <v-btn color="primary" icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <p>Om uw account beter te beveiligen, is het noodzakelijk om tweestapsverificatie in te stellen. Dit
              houdt in dat je, naast jouw wachtwoord, een tweede vorm van verificatie nodig hebt om in te
              loggen.
              Hiervoor heb je een authenticator-app nodig.</p>
            <p>Je kunt een van de volgende authenticator-apps gebruiken:</p>
            <ul>
              <li><strong>Google Authenticator</strong> - Beschikbaar voor <a
                  href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                  target="_blank">Android</a> en <a
                  href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank">iOS</a>.</li>
              <li><strong>Microsoft Authenticator</strong> - Beschikbaar voor <a
                  href="https://play.google.com/store/apps/details?id=com.azure.authenticator"
                  target="_blank">Android</a> en
                <a href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458" target="_blank">iOS</a>.
              </li>
              <li><strong>Authy</strong> - Beschikbaar voor <a
                  href="https://play.google.com/store/apps/details?id=com.authy.authy" target="_blank">Android</a>, <a
                  href="https://apps.apple.com/us/app/authy/id494168017" target="_blank">iOS</a>, en als
                desktopapplicatie.</li>
            </ul>
            <p class="mt-2">Volg na het downloaden van de app de instructies om tweestapsverificatie voor jouw
              account in te
              stellen. Dit zorgt voor een extra laag beveiliging en beschermt uw gegevens tegen onbevoegde
              toegang.</p>

            <!-- hulp nodig? Bel $config.email, $config.phone_number -->

            <p class="mt-5">Heb je hulp nodig? Neem dan contact op met onze klantenservice via <a
                :href="`mailto:${$config.email}`">{{ $config.email }}</a> of bel naar <a
                :href="`tel:${$config.phone_number}`">{{ $config.phone_number }}</a>.</p>

          </v-card-text>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
export default {
  name: "PasswordReset",
  data() {
    return {
      dialog: false,
      valid: false,
      loading: false,
      status: "idle",
      mfa_code: "",
      current_url: null,
      error_message: "",
      mfa_rules: [
        (v) => !!v || this.$t("Dit veld is verplicht"),
        (v) => (v && v.length == 6) || this.$t("De code moet 6 cijfers bevatten"),
        (v) => (v && /^\d+$/.test(v)) || this.$t("De code mag alleen cijfers bevatten"),
      ],
    };
  },
  created() {
    var re = new RegExp(/^.*\//);
    this.current_url = re.exec(window.location.href)[0];
  },
  methods: {
    toVerify() {
      this.$router.push(`/mfa-setup?session=${this.$route.query.session}&username=${this.$route.query.username}`);
    },
    send() {
      this.status = "idle";
      this.$refs.verify_account.validate();

      if (this.valid) {
        this.loading = true;
        axios
          .post(this.$config.backend_url + "/verify-mfa", {
            mfa_code: this.mfa_code,
            session: this.$route.query.session,
            username: this.$route.query.username,
          }, {
            headers: {
              "x-portal-instance-key": process.env.VUE_APP_INSTANCE_KEY,
            },
          })
          .then((response) => {
            this.loading = false;
            this.status = "success";
            this.$refs.verify_account.reset();

            // set response.data.accessToken in as token localstorage
            localStorage.setItem("token", response.data);

            // wait 2 seconds before redirecting to dashboard
            setTimeout(() => {
              this.$router.push("/");
            }, 1500);

          })
          .catch((error) => {
            this.loading = false;
            this.status = "error";
            this.error_message = error.response.data.error;
          });
      }
    },
  },

};
</script>

<style lang="scss"></style>
