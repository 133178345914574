import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"widget",attrs:{"shaped":_vm.$config.shaped_widgets}},[_c(VCardTitle,{attrs:{"flat":""}},[_c(VCardTitle,{staticClass:"mb-0 py-0 font-weight-bold text--secondary"},[_vm._v(_vm._s(_vm.$t("informalCareWidget")))]),_c(VSpacer),_c(VIcon,{staticClass:"align-right mr-3",attrs:{"x-large":""}},[_vm._v("mdi-heart")])],1),_c(VCardText,[(_vm.loading)?_c('loader'):[_c(VDataTable,{staticClass:"adjusted-margin",attrs:{"headers":_vm.headers,"items":_vm.care_requests,"items-per-page":4,"sort-desc":"","sort-by":"created_at","hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item.created_at",fn:function({ item }){return [_c('small',[_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at,"DD-MM-YYYY"))+" ")])]}},{key:"item.status",fn:function({ item }){return [_c('small',[_vm._v(" "+_vm._s(_vm.$t(item.status))+" ")])]}},{key:"item.approval_status",fn:function({ item }){return [_c('small',[_vm._v(" "+_vm._s(_vm.$t(item.approval_status))+" ")])]}}])})]],2),_c(VFooter,{class:_vm.loading ? 'pa-5' : '',attrs:{"dark":"","color":"primary"}},[_c(VSpacer),(!_vm.loading)?_c('informal-care-request-modal',{attrs:{"has_open_request":_vm.checkOpenRequest},on:{"refresh":_vm.getCareRequests}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }