<template>
  <v-row>
    <v-col>
      <v-card flat max-width="600" class="mx-auto">
        <div class="text-left">
          <router-link to="login">
            <v-icon small>mdi-arrow-left</v-icon>
            {{ $t("gotoLogin") }}
          </router-link>
        </div>
        <v-card-title>
          {{ $t("Tweestapsverificatie instellen") }}
        </v-card-title>
        <v-card-text>
          <p>{{ intro }}</p>
          <MFAConfiguration @mfa-enabled="redirect" />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import MFAConfiguration from '../components/MFAConfiguration.vue';

export default {
  name: "PasswordReset",
  components: {
    MFAConfiguration
  },
  data() {
    return {
      intro: this.$t('Voeg een extra beveiligingslaag toe om ongeautoriseerde toegang te blokkeren en je account te beschermen.')
    };
  },
  methods: {
    redirect() {
      this.$router.push({ name: "dashboard" });
    }
  }
};
</script>
